import React, { useState, useEffect } from "react";
import "./Navbar.css";
import logoImage from "../../img/LOGO.jpeg";
import { Modal, Button } from "react-bootstrap";
import {
  FaHome,
  FaShoppingCart,
  FaInfoCircle,
  FaEnvelope,
  FaHeart,
  FaBell,
} from "react-icons/fa";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useNavigate } from "react-router-dom";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore"; // Import Firestore functions
import { db } from "../../Firebase";
import AsyncStorage from "@react-native-async-storage/async-storage";

function OffcanvasExample() {
  const navigate = useNavigate();

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [loginError, setLoginError] = useState(null); // Define login error state
  const [userProfile, setUserProfile] = useState(null);
  const [cart, setCart] = useState([]);
  const [notification, setNotification] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [error, setError] = useState("");
  const [errorp, setErrorp] = useState("");
  const [password, setPassword] = useState("");
  const [signupError, setSignupError] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleMobileNumberChange = (event) => {
    const value = event.target.value;
    // Remove non-digit characters from the input
    const cleanedValue = value.replace(/\D/g, "");

    // Limit the input to 10 digits
    const limitedValue = cleanedValue.slice(0, 10);

    // Update the input field with the limited value
    setMobileNumber(limitedValue);

    // Check if the cleaned value has exactly 10 digits
    if (cleanedValue.length <= 10) {
      setError("");
    } else {
      setError("Mobile number should be 10 digits");
    }
  };

  const handlePasswordChange = (event) => {
    const value = event.target.value;

    if (value.length <= 8) {
      setPassword(value);
      setErrorp("");
    } else {
      setErrorp("Password should be 8 characters or less");
    }
  };
  const history = useNavigate();

  const openLoginModal = () => {
    setShowLoginModal(true);
    setShowOffcanvas(false);
  };

  const closeLoginModal = () => {
    setShowLoginModal(false);
  };

  // const openSignupModal = () => {
  //   setShowSignupModal(true);
  // };

  // const closeSignupModal = () => {
  //   setShowSignupModal(false);
  // };

  // const registerUser = async (userData) => {
  //   try {
  //     // Define a reference to the "users" collection (or any collection name you prefer)
  //     const usersCollection = collection(db, "users");

  //     // Add the user's data to the Firestore collection
  //     const docRef = await addDoc(usersCollection, userData);

  //     console.log("User registered successfully with ID: ", docRef.id);
  //     alert("User registered successfully with ID: ", docRef.id);

  //     return docRef.id; // You can return the document ID if needed
  //   } catch (error) {
  //     console.error("Error registering user: ", error);
  //     throw error; // Throw the error for handling in the calling code
  //   }
  // };

  useEffect(() => {
    // Check if the user is already logged in (stored in AsyncStorage)
    AsyncStorage.getItem("userData").then((userData) => {
      if (userData) {
        // If user data exists, parse it and set the user profile
        const parsedUserData = JSON.parse(userData);
        setUserProfile(parsedUserData);
      }
    });
  }, 1000);
  const fetchCartData = async () => {
    try {
      const cartData = await AsyncStorage.getItem("cart");
      if (cartData) {
        const parsedCartData = JSON.parse(cartData);
        setCart(parsedCartData);
      }
    } catch (error) {
      console.error("Error fetching cart data:", error);
    }
  };

  // Initial load of cart data
  useEffect(() => {
    fetchCartData();
  }, []);

  // Periodically refresh cart data every 10 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchCartData(); // Fetch and update cart data
    }, 1000); // 10 seconds in milliseconds

    return () => {
      // Clean up the interval when the component unmounts
      clearInterval(intervalId);
    };
  }, []);

  // const handleSignup = async (e) => {
  //   e.preventDefault();
  //   const email = e.target.email.value;
  //   const password = e.target.password.value;
  //   const name = e.target.name.value;
  //   const number = e.target.number.value;
  //   const city = e.target.city.value;

  //   if (!name || !city || !email || !mobileNumber || !password) {
  //     setSignupError("Please fill all fields");
  //     return;
  //   }

  //   try {
  //     // Check if a user with the same email already exists
  //     const usersCollection = collection(db, "users");
  //     const duplicateUserQuery = query(
  //       usersCollection,
  //       where("email", "==", email)
  //     );
  //     const duplicateUserSnapshot = await getDocs(duplicateUserQuery);

  //     if (!duplicateUserSnapshot.empty) {
  //       setSignupError("User with this email already exists");
  //       return;
  //     }

  //     // Create a userData object with the user's information
  //     const userData = {
  //       name: name,
  //       email: email,
  //       number: number,
  //       city: city,
  //       password: password,
  //       type: "Retailer",
  //       status: "Offline",
  //       login: "Enable",
  //     };

  //     // Call the registerUser function to register the user in Firestore
  //     const userId = await registerUser(userData);

  //     if (userData.type === "Admin") {
  //       history.push("/admin");
  //     } else if (userData.type === "Distributer") {
  //       history.push("/dbrhome");
  //     } else if (userData.type === "RSO") {
  //       history.push("/rsohome");
  //     } else {
  //       history.push("/products");
  //     }

  //     // Optionally, you can add code here to handle authentication with Firebase Authentication if needed
  //     await AsyncStorage.setItem("userData", JSON.stringify(userData));

  //     setSignupError("");
  //     // Close the signup modal after successful registration
  //     closeSignupModal();
  //   } catch (error) {
  //     // Handle the error, such as displaying an error message to the user
  //     console.error("Error in registration: ", error);
  //   }
  // };

  const handleLogin = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    const Enable = "Enable";

    console.log("Attempting login with email:", email);

    try {
      // Define a reference to the "users" collection (or your collection name)
      const usersCollection = collection(db, "users");

      // Query the users collection to find a user with matching email and password
      const userQuery = query(
        usersCollection,
        where("email", "==", email),
        where("password", "==", password),
        where("login", "==", Enable)
      );

      const querySnapshot = await getDocs(userQuery);

      if (!querySnapshot.empty) {
        // User with matching email and password found
        const userData = querySnapshot.docs[0].data();
        const userId = querySnapshot.docs[0].id; // Get the user's document ID
        const userDocRef = doc(usersCollection, userId);

        // Update the user's status to 'Online'
        await updateDoc(userDocRef, { status: "Online" });

        console.log("Login successful. User data:", userData);
        alert("Login successful. User data:", userData);

        if (userData.type === "Admin") {
          history("/admin");
        } else if (userData.type === "Distributer") {
          history("/dbrhome");
        } else if (userData.type === "RSO") {
          history("/rsohome");
        } else if (userData.type === "Retailer") {
          history("/rtlhome");
        } else {
          history("/products");
        }

        localStorage.setItem("userData", JSON.stringify(userData));
        await AsyncStorage.setItem("userData", JSON.stringify(userData));
        // Close the login modal or perform other actions as needed
        closeLoginModal();
      } else {
        // No user found with the provided email and password
        console.log("No user found with email:", email);
        alert("No user found with email:", email);
        setLoginError("Invalid email or password"); // Set login error message
      }
    } catch (error) {
      // Handle any errors that occur during login
      console.error("Error during login:", error);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.getElementById("navbar"); // Change 'navbar' to the ID you set on your Navbar
      if (window.scrollY > 0) {
        navbar.classList.add("fixed-navbar");
      } else {
        navbar.classList.remove("fixed-navbar");
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLogout = async () => {
    // Remove user data from AsyncStorage and reset the user profile state
    await AsyncStorage.removeItem("userData");
    setUserProfile(null);
    navigate("/");
  };

  useEffect(() => {
    fetchNotification();
  }, []);

  const fetchNotification = async () => {
    try {
      const UserData = await AsyncStorage.getItem("userData");
      const parseData = JSON.parse(UserData);
      const emailId = parseData.email;

      const itemsCollection = collection(db, "notifications");
      const itemsSnapshot = await getDocs(itemsCollection);
      if (itemsSnapshot.docs.length === 0) {
        console.log("No notifications found.");
        // Handle accordingly, e.g., set an empty array or display a message.
      } else {
        // Continue processing data.
      }

      // Filter orders by user email
      const ordersData = itemsSnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((order) => order.toid === emailId);
      console.log("Notification", ordersData);

      // Sort notifications by timestamp in descending order
      ordersData.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds);

      setNotification(ordersData);
      console.log("Notification556", notification);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  return (
    <>
      {["lg"].map((expand) => (
        <Navbar key={expand} expand={expand} className="" id="navbar">
          <Container fluid>
            <div className="logo">
              <img src={logoImage} alt="EcomArea Logo" />
            </div>
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              onClick={() => setShowOffcanvas(true)}

              // style={{ color: 'white' }}
            />

            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              show={showOffcanvas}

              // style={{ color: 'white' }}
            >
              <Offcanvas.Header
                closeButton
                onClick={() => setShowOffcanvas(false)}
              >
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Shopping Hub
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-center flex-grow-1 custom-nav">
                  <Nav.Link
                    as={Link}
                    to="/"
                    className="nav-link"
                    onClick={() => {
                      console.log("Nav link clicked");
                      setShowOffcanvas(false);
                    }}
                  >
                    <FaHome /> Home{" "}
                  </Nav.Link>

                  <Nav.Link
                    onClick={() => setShowOffcanvas(false)}
                    as={Link}
                    to="/products"
                    className="nav-link"
                  >
                    {" "}
                    <FaHeart /> Products
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/category"
                    className="nav-link"
                    onClick={() => setShowOffcanvas(false)}
                  >
                    {" "}
                    <FaHeart /> All Order
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/about"
                    className="nav-link"
                    onClick={() => setShowOffcanvas(false)}
                  >
                    {" "}
                    <FaInfoCircle /> About
                  </Nav.Link>

                  <Nav.Link
                    as={Link}
                    to="/contact"
                    className="nav-link"
                    onClick={() => setShowOffcanvas(false)}
                  >
                    {" "}
                    <FaEnvelope /> Contact
                  </Nav.Link>
                </Nav>
                <hr />
                <Nav className="justify-content-end flex-grow-1 pe-3 custom-nav">
                  <Nav.Link className="nav-link" onClick={handleOpenModal}>
                    <FaBell style={{ color: "orange" }} />
                    <span style={{ color: "black", fontWeight: "bold" }}>
                      {" "}
                      [{notification.length}]{" "}
                    </span>
                  </Nav.Link>
                  <Nav.Link as={Link} to="/cart" className="nav-link">
                    <FaShoppingCart />
                    <span style={{ color: "red", fontWeight: "bold" }}>
                      {" "}
                      [{cart.length}]{" "}
                    </span>
                  </Nav.Link>
                  {/* <div className='m-3'></div> */}
                  {userProfile ? (
                    <div>
                      <button className="auth-button" onClick={handleLogout}>
                        Logout
                      </button>
                    </div>
                  ) : (
                    <>
                      <button
                        className="auth-button"
                        onClick={() => {
                          navigate("/register/0");
                          setShowOffcanvas(false);
                        }}
                      >
                        Sign Up
                      </button>

                      <hr></hr>
                      <br></br>
                      <button className="auth-button" onClick={openLoginModal}>
                        Sign In
                      </button>
                    </>
                  )}
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>

          {showLoginModal && (
            <div
              className="modal-overlay"
              onClick={closeLoginModal}
              style={{ zIndex: 1000 }}
            >
              <div
                className="modal-content"
                onClick={(e) => e.stopPropagation()}
              >
                <form onSubmit={handleLogin}>
                  <h2>Sign In</h2>
                  <hr />
                  <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Enter your email"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <div className="password-input">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        placeholder="Enter your password"
                      />
                      <h6
                        className="password-toggle-button"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? "Hide" : "Show"}
                      </h6>
                    </div>
                  </div>
                  <div style={{ flexDirection: "row" }}>
                    <button
                      type="submit"
                      className="auth-button2 m-3"
                      style={{ backgroundColor: "blue", borderRadius: "20px" }}
                    >
                      Login
                    </button>
                    <button
                      className="auth-button3 m-3"
                      onClick={closeLoginModal}
                    >
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {/* {showSignupModal && (
            <div
              className="modal-overlay"
              onClick={closeSignupModal}
              style={{ zIndex: 1000 }}
            >
              <div
                className="modal-content"
                onClick={(e) => e.stopPropagation()}
              >
                <form onSubmit={handleSignup}>
                  <h2>Sign Up</h2>
                  <hr></hr>
                  {signupError && (
                    <div className="error-message">{signupError}</div>
                  )}
                  <div className="form-group">
                    <label htmlFor="username">Name:</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Enter your username"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="number">Mobile No:</label>
                    <input
                      type="number"
                      id="number"
                      name="number"
                      placeholder="Enter your Mobile number"
                      value={mobileNumber}
                      onChange={handleMobileNumberChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="city">City:</label>
                    <input
                      type="text"
                      id="city"
                      name="city"
                      placeholder="Enter your City"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="username">Email:</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Enter your Email"
                    />
                  </div>

                  {error && <div className="error-message">{error}</div>}

                  <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <div className="password-input">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        placeholder="Enter your password"
                        value={password}
                        onChange={handlePasswordChange}
                      />
                      <h6
                        className="password-toggle-button"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? "Hide" : "Show"}
                      </h6>
                    </div>
                  </div>
                  {errorp && <div className="error-message">{errorp}</div>}

                  <div style={{ flexDirection: "row" }}>
                    <button
                      type="submit"
                      className="auth-button2 m-3"
                      style={{ backgroundColor: "blue", borderRadius: "20px" }}
                    >
                      Sign Up
                    </button>
                    <button
                      className="auth-button3 m-3"
                      onClick={closeSignupModal}
                    >
                      Close
                    </button>
                    <a
                      href="/register/0"
                      className="register-link"
                      style={{
                        color: "blue",
                        textDecoration: "none",
                        fontWeight: "bold",
                        marginTop: "10px",
                        display: "block",
                      }}
                    >
                      Register as Staff
                    </a>
                  </div>
                </form>
              </div>
            </div>
          )} */}
        </Navbar>
      ))}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>All Notifications</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Display all notifications dynamically */}
          {notification && notification.length > 0 ? (
            notification.map((notif, index) => (
              <div
                key={index}
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: 2,
                  padding: 10,
                  margin: 10,
                }}
              >
                <h4>{notif.subject}</h4>
                <p style={{ color: "green" }}>From: {notif.fromid}</p>
                <p>Message: {notif.message}</p>
                {/* Add other fields as needed */}
                <small>
                  Sent at:{" "}
                  {new Date(notif.timestamp?.seconds * 1000).toLocaleString()}
                </small>
              </div>
            ))
          ) : (
            <p>No notifications found.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          {/* Additional buttons or actions if needed */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default OffcanvasExample;
