import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  collection,
  query,
  where,
  doc,
  addDoc,
  getDocs,
  setDoc,
} from "firebase/firestore";
import { db } from "../../Firebase";
import { useNavigate } from "react-router-dom";

function SignupForm() {
  const history = useNavigate();

  const { email } = useParams();
  console.log(email);

  const [userType, setUserType] = useState("Retailer");
  const [distributers, setDistributers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    number: "",
    email: "",
    firmName: "",
    address: "",
    gstNo: "",
    regNo: "",
    city: "",
    area: "",
    joiningDate: "",
    distributer: "",
    password: "",
    rso: "",
    outletType: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUserTypeChange = (e) => {
    setUserType(e.target.value);
  };

  useEffect(() => {
    const fetchDistributers = async () => {
      try {
        const distributersQuery = query(collection(db, "users"));
        const querySnapshot = await getDocs(distributersQuery);

        const distributersData = [];
        querySnapshot.forEach((doc) => {
          distributersData.push({ id: doc.id, ...doc.data() });
        });

        setDistributers(distributersData);
      } catch (error) {
        console.error("Error fetching distributers:", error);
      }
    };

    fetchDistributers();
  }, [userType]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const {
      name,
      number,
      email,
      password,
      firmName,
      address,
      gstNo,
      regNo,
      city,
      area,
      joiningDate,
      distributer,
    } = formData;

    if (
      !name ||
      !number ||
      !email ||
      !password ||
      (userType === "Distributor" &&
        (!firmName || !address || !gstNo || !regNo || !city)) ||
      (userType === "RSO" &&
        (!address || !city || !area || !joiningDate || !distributer))
    ) {
      alert("Please fill in all required fields.");
      return;
    }

    try {
      setIsLoading(true);

      const userCollection = collection(db, "users");
      const userQuery = query(
        userCollection,
        where("email", "==", formData.email)
      );
      const querySnapshot = await getDocs(userQuery);

      if (!querySnapshot.empty) {
        // Email already exists, show alert
        alert("Email is already present. Please use another email.");
        setIsLoading(false);
        return;
      }

      const numberQuery = query(
        userCollection,
        where("number", "==", formData.number)
      );
      const numberSnapshot = await getDocs(numberQuery);

      if (!numberSnapshot.empty) {
        // Number already exists, show alert
        alert("Number is already present. Please use another number.");
        setIsLoading(false);
        return;
      }

      if (email && !querySnapshot.empty) {
        // Email exists and provided in the URL params, perform update
        const docId = querySnapshot.docs[0].id;

        const updatedUser = {
          ...formData,
          type: userType,
          login: "Enable",
        };

        await setDoc(doc(userCollection, docId), updatedUser);

        alert("User updated successfully!");

        if (userType === "RSO") {
          history("/RSO");
        } else if (userType === "Distributer") {
          history("/DRBR");
        } else {
          history("/");
        }
      } else {
        // Email does not exist in URL params or not found in database, perform insert
        const newUser = {
          ...formData,
          type: userType,
          login: "Disable",
        };

        await addDoc(userCollection, newUser);

        alert("User registered successfully!");
      }

      setFormData({
        name: "",
        number: "",
        email: "",
        password: "",
        firmName: "",
        address: "",
        gstNo: "",
        regNo: "",
        city: "",
        area: "",
        joiningDate: "",
        distributer: "",
      });

      setUserType("Retailer");
      setIsLoading(false);
    } catch (error) {
      console.error("Error updating/inserting user:", error);
      alert("Failed to update/insert user. Please try again.");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchUserByEmail = async () => {
      try {
        const userQuery = query(
          collection(db, "users"),
          where("email", "==", email)
        );
        const querySnapshot = await getDocs(userQuery);

        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data();
          setFormData((prevData) => ({ ...prevData, ...userData }));
          setUserType(userData.type);
          // Additional logic based on user type if needed
        } else {
          // Handle case when user with the email is not found
          console.log("User not found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    if (email) {
      fetchUserByEmail();
    }
  }, [email]);

  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        maxWidth: "800px",
        margin: "auto",
        padding: "20px",
        backgroundColor: "#f9f9f9",
        border: "1px solid #ddd",
        borderRadius: "5px",
      }}
    >
      <h2 style={{ textAlign: "center", marginBottom: "20px" }}>Signup Form</h2>
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <label
          htmlFor="userType"
          style={{ display: "block", marginBottom: "10px" }}
        >
          Select User Type:
        </label>
        <select
          id="userType"
          name="userType"
          value={userType}
          onChange={handleUserTypeChange}
          style={{ width: "100%", padding: "5px", marginBottom: "20px" }}
        >
          <option value="Retailer">Retailer</option>
          <option value="Distributer">Distributor</option>
          <option value="RSO">RSO</option>
        </select>

        <div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div style={{ width: "50%", paddingRight: "10px" }}>
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                style={{ width: "100%", padding: "5px", marginBottom: "10px" }}
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
            <div style={{ width: "50%", paddingLeft: "10px" }}>
              <label htmlFor="number">Number:</label>
              <input
                type="text"
                id="number"
                name="number"
                style={{ width: "100%", padding: "5px", marginBottom: "10px" }}
                value={formData.number}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div style={{ width: "50%", paddingRight: "10px" }}>
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                style={{ width: "100%", padding: "5px", marginBottom: "10px" }}
                value={formData.email}
                onChange={handleInputChange}
                readOnly={Boolean(formData.email === 0)}
              />
            </div>
            <div style={{ width: "50%", paddingLeft: "10px" }}>
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                name="password"
                style={{ width: "100%", padding: "5px", marginBottom: "10px" }}
                value={formData.password}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {userType === "Distributer" && (
            <>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div style={{ width: "20%", paddingRight: "10px" }}>
                  <label htmlFor="city">City:</label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    style={{
                      width: "100%",
                      padding: "5px",
                      marginBottom: "10px",
                    }}
                    value={formData.city}
                    onChange={handleInputChange}
                  />
                </div>
                <div style={{ width: "80%", paddingLeft: "10px" }}>
                  <label htmlFor="address">Address:</label>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    style={{
                      width: "100%",
                      padding: "5px",
                      marginBottom: "10px",
                    }}
                    value={formData.address}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <label htmlFor="firmName">Firm Name:</label>
              <input
                type="text"
                id="firmName"
                name="firmName"
                style={{ width: "100%", padding: "5px", marginBottom: "10px" }}
                value={formData.firmName}
                onChange={handleInputChange}
              />
              <label htmlFor="regNo">Reg. No.:</label>
              <input
                type="text"
                id="regNo"
                name="regNo"
                style={{ width: "100%", padding: "5px", marginBottom: "10px" }}
                value={formData.regNo}
                onChange={handleInputChange}
              />
              <label htmlFor="gstNo">GST No.:</label>
              <input
                type="text"
                id="gstNo"
                name="gstNo"
                style={{ width: "100%", padding: "5px", marginBottom: "10px" }}
                value={formData.gstNo}
                onChange={handleInputChange}
              />

              <input type="hidden" id="type" name="type" value="Distributer" />
            </>
          )}
        </div>

        {userType === "RSO" && (
          <>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div style={{ width: "20%", paddingRight: "10px" }}>
                <label htmlFor="city">City:</label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  style={{
                    width: "100%",
                    padding: "5px",
                    marginBottom: "10px",
                  }}
                  value={formData.city}
                  onChange={handleInputChange}
                />
              </div>
              <div style={{ width: "80%", paddingLeft: "10px" }}>
                <label htmlFor="address">Address:</label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  style={{
                    width: "100%",
                    padding: "5px",
                    marginBottom: "10px",
                  }}
                  value={formData.address}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <label htmlFor="area">Area:</label>
            <input
              type="text"
              id="area"
              name="area"
              style={{ width: "100%", padding: "5px", marginBottom: "10px" }}
              value={formData.area}
              onChange={handleInputChange}
            />
            <label htmlFor="joiningDate">Date of Joining:</label>
            <input
              type="date"
              id="joiningDate"
              name="joiningDate"
              style={{ width: "100%", padding: "5px", marginBottom: "10px" }}
              value={formData.joiningDate}
              onChange={handleInputChange}
            />
            <label htmlFor="rsoDistributer">Distributor:</label>
            <select
              id="distributer"
              name="distributer"
              style={{ width: "100%", padding: "5px", marginBottom: "10px" }}
              value={formData.distributer}
              onChange={handleInputChange}
            >
              <option value="">Select Distributor</option>
              {distributers
                .filter((user) => user.type === "Distributer")
                .map((users) => (
                  <option key={users.id} value={users.name}>
                    {users.name}
                  </option>
                ))}
            </select>
            <input type="hidden" id="type" name="type" value="RSO" />
          </>
        )}

        {userType === "Retailer" && (
          <>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div style={{ width: "20%", paddingRight: "10px" }}>
                <label htmlFor="city">City:</label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  style={{
                    width: "100%",
                    padding: "5px",
                    marginBottom: "10px",
                  }}
                  value={formData.city}
                  onChange={handleInputChange}
                />
              </div>
              <div style={{ width: "80%", paddingLeft: "10px" }}>
                <label htmlFor="address">Address:</label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  style={{
                    width: "100%",
                    padding: "5px",
                    marginBottom: "10px",
                  }}
                  value={formData.address}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <label htmlFor="joiningDate">Outlet Name:</label>
            <input
              type="text"
              id="firmName"
              name="firmName"
              style={{ width: "100%", padding: "5px", marginBottom: "10px" }}
              value={formData.firmName}
              onChange={handleInputChange}
            />

            <label htmlFor="outletType">Outlet Type:</label>
            <select
              id="outletType"
              name="outletType"
              style={{ width: "100%", padding: "5px", marginBottom: "10px" }}
              value={formData.outletType}
              onChange={handleInputChange}
            >
              <option value="">Select Outlet Type</option>
              <option value="Clothing Store">Clothing Store</option>
              <option value="Electronics Store">Electronics Store</option>
              <option value="Grocery Store">Grocery Store</option>
              <option value="Furniture Store">Furniture Store</option>
              <option value="Pharmacy">Pharmacy</option>
              <option value="Bookstore">Bookstore</option>
              <option value="Hardware Store">Hardware Store</option>
              <option value="Toy Store">Toy Store</option>
              <option value="Jewelry Store">Jewelry Store</option>
              <option value="Pet Store">Pet Store</option>
              <option value="Other">Other</option>
            </select>

            <div className="d-flex">
              <div style={{ width: "50%", paddingRight: "10px" }}>
                <label htmlFor="rsoDistributer">Distributor:</label>
                <select
                  id="distributer"
                  name="distributer"
                  style={{
                    width: "100%",
                    padding: "5px",
                    marginBottom: "10px",
                  }}
                  value={formData.distributer}
                  onChange={handleInputChange}
                >
                  <option value="">Select Distributor</option>
                  {distributers
                    .filter(
                      (user) =>
                        user.type === "Distributer" &&
                        user.city
                          .toLowerCase()
                          .includes(formData.city.toLowerCase())
                    )
                    .map((users) => (
                      <option key={users.id} value={users.name}>
                        {users.name}
                      </option>
                    ))}
                </select>
              </div>
              <div style={{ width: "50%", paddingRight: "10px" }}>
                <label htmlFor="rsoDistributer">RSO:</label>
                <select
                  id="rso"
                  name="rso"
                  style={{
                    width: "100%",
                    padding: "5px",
                    marginBottom: "10px",
                  }}
                  value={formData.rso}
                  onChange={handleInputChange}
                >
                  <option value="">Select RSO</option>
                  {distributers
                    .filter(
                      (user) =>
                        user.type === "RSO" &&
                        user.distributer == formData.distributer
                    )
                    .map((distributer) => (
                      <option key={distributer.id} value={distributer.name}>
                        {distributer.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <input type="hidden" id="type" name="type" value="Retailer" />
          </>
        )}

        {isLoading ? (
          <div style={{ textAlign: "center", fontWeight: "bold" }}>
            Loading...
          </div>
        ) : (
          <>
            <input
              type="submit"
              value="Submit"
              style={{
                width: "100%",
                padding: "10px",
                backgroundColor: "#007bff",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontWeight: "bold",
              }}
            />
          </>
        )}
      </form>
    </div>
  );
}

export default SignupForm;
