import { ReactComponentElement, useState, useEffect } from "react";
import Sidebar from "../Sidebar";
import "../Style.css";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore"; // Import Firestore functions
import { db } from "../../../Firebase";
import Header from "../../SubComponent/Header";
import { useNavigate } from "react-router-dom";

export default function Admin() {
  const history = useNavigate();

  const [users, setUsers] = useState([]);

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    try {
      const usersCollection = collection(db, "users"); // Reference to the 'users' collection
      const userSnapshot = await getDocs(usersCollection);
      const usersData = userSnapshot.docs.map((doc) => doc.data());
      setUsers(usersData);
    } catch (error) {
      console.log("Error fetching all users:", error);
    }
  };

  const updateUserLoginStatus = async (email, currentStatus) => {
    try {
      const usersCollection = collection(db, "users");
      const querySnapshot = await getDocs(usersCollection);
      querySnapshot.forEach(async (userDoc) => {
        const userData = userDoc.data();
        if (userData.email === email) {
          await updateDoc(doc(db, "users", userDoc.id), {
            login: currentStatus === "Enable" ? "Disable" : "Enable",
          });
          getAllUsers(); // Fetch updated user list
          console.log(`User login status updated for ${email}`);
          alert(`User login status updated for ${email}`);
        }
      });
    } catch (error) {
      console.log("Error updating user login status:", error);
    }
  };

  return (
    <div style={{ overflow: "hidden", marginRight: "20px" }}>
      <div className="row" style={{ overflow: "hidden" }}>
        {/* Sidebar (left side) */}
        <div className="col-md-3">
          <Sidebar />
        </div>

        {/* Content (right side) */}
        <main className="col-md-9">
          <Header />
          <br />
          <div className="mt-5 pt-5">
            <h1
              style={{
                fontSize: 30,
                fontWeight: "bold",
                fontStyle: "italic",
                marginTop: 10,
                marginBottom: 20,
                marginRight: 20,
                color: "blue",
              }}
            >
              All RSOs
            </h1>
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Number</th>
                  <th>Email</th>
                  <th>Password</th>
                  <th>Address</th>
                  <th>Area</th>
                  <th>JoiningDate</th>
                  <th>Distributer</th>
                  <th>Status</th>
                  <th style={{ textAlign: "center" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {users
                  .filter((user) => user.type === "RSO")
                  .map((user) => (
                    <tr key={user.email}>
                      <td>{user.name}</td>
                      <td>{user.number}</td>
                      <td>{user.email}</td>
                      <td>{user.password}</td>
                      <td>{user.address}</td>
                      <td>{user.area}</td>
                      <td>{user.joiningDate}</td>
                      <td>{user.distributer}</td>
                      <td>{user.login}</td>
                      <td>
                        <button
                          onClick={() =>
                            updateUserLoginStatus(user.email, user.login)
                          }
                          style={{
                            backgroundColor: "blue",
                            color: "white",
                            border: "2px solid green",
                            borderRadius: "10px",
                            marginLeft: 5,
                          }}
                        >
                          {user.login === "Enable" ? "Disable" : "Enable"}
                        </button>
                        <button
                          onClick={() => history(`/register/${user.email}`)}
                          style={{
                            backgroundColor: "green",
                            color: "white",
                            border: "2px solid orange",
                            borderRadius: "10px",
                            marginLeft: 5,
                          }}
                        >
                          Update
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </main>
      </div>
    </div>
  );
}
