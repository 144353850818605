import React, { useState, useEffect } from "react";
import logoImage from "../../img/LOGO.jpeg";

export default function Header() {
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      // Check the screen width and update the isHidden state accordingly
      if (window.innerWidth < 822 || window.innerWidth < 400) {
        setIsHidden(true);
      } else {
        setIsHidden(false);
      }
    };

    // Add an event listener for the window resize event
    window.addEventListener("resize", handleResize);

    // Call handleResize once on initial render
    handleResize();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div
        style={{
          position: "fixed",
          backgroundColor: "white",
          width: "100%",
          height: "12vh",
          display: isHidden ? "none" : "block", // Conditionally hide the header
        }}
      >
        <img
          src={logoImage}
          alt="EcomArea Logo"
          style={{ height: 90, width: 200 }}
        />
      </div>
    </>
  );
}
