import React, {useEffect, useState} from "react";

import { useNavigate } from "react-router-dom";
import {
  FaHome,
  FaShoppingCart,
  FaInfoCircle,
  FaEnvelope,
  FaHeart,
  FaBellSlash,
  FaBell
} from "react-icons/fa";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  getFirestore,
  collection,
  addDoc,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore"; // Import Firestore functions
import { db } from "../../Firebase";
import Nav from "react-bootstrap/Nav";
import {Modal, Button } from "react-bootstrap";
import logoImage from "../../img/LOGO.jpeg"
const DBRHeader = () => {
  const history = useNavigate();
  const [notification, setNotification] = useState([]);
  const [showModal, setShowModal] = useState(false);
 
  const handleOpenModal = () => {
    setShowModal(true);
  };

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    fetchNotification();
  }, []);

  const fetchNotification = async () => {
    try {
      const UserData = await AsyncStorage.getItem("userData");
      const parseData = JSON.parse(UserData);
      const emailId = parseData.name;

      const itemsCollection = collection(db, "notifications");
      const itemsSnapshot = await getDocs(itemsCollection);
      if (itemsSnapshot.docs.length === 0) {
        console.log("No notifications found.");
        // Handle accordingly, e.g., set an empty array or display a message.
      } else {
        // Continue processing data.
      }
    
      // Filter orders by user email
      const ordersData = itemsSnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((order) => order.dbr === emailId);
    console.log("Notification",ordersData);
     
      setNotification(ordersData);
      console.log("Notification556",notification);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };
 
  return (
    <>
      

           <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>  <img src={logoImage} alt="EcomArea Logo" style={{height:90,width:200}}/>
           <Nav.Link  className="nav-link" onClick={handleOpenModal} style={{margin:20, boxShadow:'1px 1px 12px 1px rgba(0,0,0,0.4)', padding:15, borderRadius:40}} >
                   
                   <FaBell style={{color:'orange'}} />
                   <span style={{ color: "black", fontWeight: "bold" }}>
                     {" "}
                     [{notification.length}]
                     {" "}
                   </span>
                 </Nav.Link>
           </div>
           
      
      <Modal show={showModal} onHide={handleCloseModal}>
  <Modal.Header closeButton>
    <Modal.Title>All Notifications</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {/* Display all notifications dynamically */}
    {notification && notification.length > 0 ? (
      notification.map((notif, index) => (
        <div key={index} style={{ border: '1px solid lightgrey',borderRadius:2, padding:10, margin:10}}>
          <h4>{notif.subject}</h4>
          <p style={{color:'green'}}>From: {notif.fromid}</p>
          <p>Message: {notif.message}</p>
          {/* Add other fields as needed */}
          <small>Sent at: {new Date(notif.timestamp?.seconds * 1000).toLocaleString()}</small>
        </div>
      ))
    ) : (
      <p>No notifications found.</p>
    )}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleCloseModal}>
      Close
    </Button>
  </Modal.Footer>
</Modal>
    </>
  );
};

export default DBRHeader;
