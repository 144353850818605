import React, { useEffect, useState } from "react";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../../../Firebase";
import Sidebar from "./SideBar";
import DBRHeader from "../../SubComponent/DBRHeader";
import "../Style.css";

export default function Product() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllOrders();
  }, []);

  const getAllOrders = async () => {
    try {
      const ordersCollection = collection(db, "dirOrder");
      const ordersSnapshot = await getDocs(ordersCollection);
      const ordersData = ordersSnapshot.docs.map((doc) => {
        const orderData = doc.data();
        return { ...orderData, id: doc.id };
      });
      setOrders(ordersData);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching orders:", error);
    }
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <div className="row" style={{ overflow: "hidden" }}>
        <div className="col-md-3">
          <Sidebar />
        </div>
        <main className="col-md-9">
          <DBRHeader />
          <br />
          <div className="mt-2">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h1
                style={{
                  fontSize: 30,
                  fontWeight: "bold",
                  fontStyle: "italic",
                  marginTop: 10,
                  marginBottom: 20,
                  marginRight: 20,
                  color: "blue",
                }}
              >
                My Orders
              </h1>
            </div>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div
                className="card-container"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                {orders.length != 0
                  ? orders.map((order) => (
                      <div
                        key={order.id}
                        style={{
                          border: "1px solid #ddd",
                          borderRadius: "10px",
                          padding: "20px",
                          marginBottom: "20px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <div>
                          <h3>Order ID: {order.id}</h3>
                          <p
                            style={{
                              backgroundColor: "lightgreen",
                              color: "darkgreen",
                              fontWeight: 600,
                              fontSize: 22,
                            }}
                          >
                            Grand Total: ₹{order.grandTotal}
                          </p>
                          <p>UserEmail : {order.userEmail}</p>
                          <p>
                            Date and Time:{" "}
                            {order.timestamp && order.timestamp.toDate
                              ? order.timestamp.toDate().toLocaleString()
                              : "Invalid Timestamp"}
                          </p>
                          <div>
                            <h4 style={{ color: "blue" }}>Products</h4>
                            <hr></hr>
                            {order.products.map((product, index) => (
                              <div
                                key={index}
                                style={{
                                  marginBottom: "10px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <p>
                                  {index + 1}. {product.title} - Quantity:{" "}
                                  {product.quantity}KG
                                </p>
                                <p>Total Price: ₹{product.totalPrice}</p>
                              </div>
                            ))}
                          </div>
                          <hr></hr>
                          <p style={{ backgroundColor: "lightYellow" }}>
                            Status :
                            <span
                              style={{
                                padding: "8px 12px",
                                borderRadius: "5px",
                                fontWeight: 600,
                                color:
                                  order.status === "Pending"
                                    ? "red"
                                    : order.status === "Approved"
                                    ? "orange"
                                    : "green",
                              }}
                            >
                              {order.status}
                            </span>
                          </p>
                        </div>
                      </div>
                    ))
                  : "Sorry, No Order available"}
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}
