import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AsyncStorage from "@react-native-async-storage/async-storage";
import "./Cart.css";
import { db } from "../../Firebase"; // Import Firebase Firestore (replace with your actual import)
import { collection, getDocs, addDoc, query, where } from "firebase/firestore";
export default function Cart() {
  const navigate = useNavigate();

  const [bagItems, setBagItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [user, setUser] = useState("");
  const [number, setNumber] = useState("");
  const [error, setError] = useState("");
  const [parsedUserData, setParsedUserData] = useState(null);
  useEffect(() => {
    // Check if the user is already logged in (stored in AsyncStorage)
    AsyncStorage.getItem("userData").then((userData) => {
      if (userData) {
        // If user data exists, parse it and set the user profile
        const parsedUserData = JSON.parse(userData);
        console.log("User Data:", parsedUserData);
        setUser(parsedUserData);
        setParsedUserData(parsedUserData);
      }
    });
  }, []);
  useEffect(() => {
    if (parsedUserData) {
      fetchDistributers();
      fetchRsos();
    }
  }, [parsedUserData]);
  // DBR and RSO dropdown
  const [distributers, setDistributers] = useState([]);
  const [rsos, setRsos] = useState([]);
  const [selectedDistributer, setSelectedDistributer] = useState("");
  const [selectedRso, setSelectedRso] = useState("");
  const [filteredRsos, setFilteredRsos] = useState([]);
  const [remarks, setRemarks] = useState([]);

  const fetchDistributers = async () => {
    try {
      const userCityLowerCase = user.city.toLowerCase();

      const distributersQuery = query(
        collection(db, "users"),
        where("type", "==", "Distributer")
      );
      const querySnapshot = await getDocs(distributersQuery);

      const distributersData = [];
      querySnapshot.forEach((doc) => {
        const distributer = doc.data();
        const distributerCityLowerCase = distributer.city.toLowerCase();

        // Compare the lowercase values of cities
        if (distributerCityLowerCase === userCityLowerCase) {
          distributersData.push({ id: doc.id, ...distributer });
        }
      });

      setDistributers(distributersData);

      console.log("Distributers:", distributersData);
    } catch (error) {
      console.error("Error fetching distributers:", error);
    }
  };

  // Fetch RSOs
  const fetchRsos = async () => {
    try {
      const rsosQuery = query(
        collection(db, "users"),
        where("type", "==", "RSO")
      );
      const querySnapshot = await getDocs(rsosQuery);
      const rsosData = [];
      querySnapshot.forEach((doc) => {
        rsosData.push({ id: doc.id, ...doc.data() });
      });
      setRsos(rsosData);
      console.log("RSOs:", rsosData);
    } catch (error) {
      console.error("Error fetching RSOs:", error);
    }
  };

  // Filter RSOs based on selected Distributer
  useEffect(() => {
    if (selectedDistributer) {
      const filteredRsos = rsos.filter(
        (rso) => rso.distributer === selectedDistributer
      );
      setFilteredRsos(filteredRsos);
    }
  }, [selectedDistributer, rsos]);

  useEffect(() => {
    fetchBagItems();
  }, []);

  const fetchBagItems = async () => {
    try {
      const BagData = await AsyncStorage.getItem("cart");
      const parsedItems = JSON.parse(BagData);
      if (parsedItems) {
        setBagItems(parsedItems);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching bag items:", error);
      setLoading(false);
    }
  };

  const removeBagItem = async (item) => {
    try {
      const updatedBagItems = bagItems.filter(
        (bagItem) => bagItem.title !== item.title
      );
      await AsyncStorage.setItem("cart", JSON.stringify(updatedBagItems));
      setBagItems(updatedBagItems);
    } catch (error) {
      console.error("Error removing item from cart:", error);
    }
  };

  const handlePress = () => {
    navigate("/");
  };

  const handleMinus = (item) => {
    const updatedBagItems = bagItems.map((bagItem) => {
      if (bagItem.title === item.title) {
        const newQuantity = Math.max(bagItem.quantity - 1, 1); // Ensure quantity is not less than 1
        return { ...bagItem, quantity: newQuantity };
      }
      return bagItem;
    });
    updateCart(updatedBagItems);
  };

  const handlePlus = (item) => {
    const updatedBagItems = bagItems.map((bagItem) => {
      if (bagItem.title === item.title) {
        const newQuantity = Math.min(bagItem.quantity + 1, item.stock); // Ensure quantity does not exceed item stock
        return { ...bagItem, quantity: newQuantity };
      }
      return bagItem;
    });
    updateCart(updatedBagItems);
  };

  const updateCart = async (updatedBagItems) => {
    try {
      await AsyncStorage.setItem("cart", JSON.stringify(updatedBagItems));
      setBagItems(updatedBagItems);
    } catch (error) {
      console.error("Error updating cart:", error);
    }
  };
  const calculateTotalAmount = () => {
    let totalAmount = 0;
    bagItems.forEach((item) => {
      totalAmount += item.quantity * item.price;
    });

    return totalAmount;
  };
  const handleRemarkChange = (event, itemId) => {
    const value = event.target.value;
    setRemarks((prevRemarks) => ({ ...prevRemarks, [itemId]: value }));
  };

  const generateOrderId = () => {
    return `ORDER-${Date.now()}-${Math.floor(Math.random() * 10000)}`;
  };

  const handleOrderPlacement = async () => {
    const alternateNumber = document.getElementById("alternateNumber").value;

    if (bagItems.length === 0) {
      alert("Your cart is empty. Add items to place an order.");
      return;
    }

    // Check if any item is missing selectedColor or selectedSize
    const itemMissingColor = bagItems.some((item) => !item.selectedColor);
    const itemMissingSize = bagItems.some((item) => !item.selectedSize);

    if (itemMissingColor) {
      alert("Please select a color for all items.");
      return;
    }

    if (itemMissingSize) {
      alert("Please select a size for all items.");
      return;
    }
    try {
      const order = {
        orderId: generateOrderId(),
        userDetails: {
          name: user.name,
          number: user.number,
          email: user.email,
          city: user.city,
          address: user.address,
          dbr: user.distributer,
          rso: user.rso,
          alternateNumber,
        },
        items: bagItems.map((item) => ({
          ...item,
          remark: remarks[item.id] || "",
        })),
        userEmail: user.email,
        orderStatus: "Pending",
        totalAmount: calculateTotalAmount(),
        timestamp: Date.now(),
      };
      // Replace 'collection' with your Firestore database reference
      const docRef = await addDoc(collection(db, "orders"), order);
      console.log("Document written with ID: ", docRef.id); // Log the document ID

      // Update stock for each item in the bag (you may need to adjust this part if needed)
      for (const bagItem of bagItems) {
        // ... your existing code to update item stock ...
      }

      await AsyncStorage.removeItem("cart");
      setBagItems([]);

      alert("Order placed successfully!");
      // navigation.navigate('Customer'); // Uncomment this line if needed
    } catch (error) {
      console.error("Error placing order:", error);
    }
  };

  const handleMobileNumberChange = (event) => {
    const value = event.target.value;
    // Remove non-digit characters from the input
    const cleanedValue = value.replace(/\D/g, "");

    // Limit the input to 10 digits
    const limitedValue = cleanedValue.slice(0, 10);

    // Check if the cleaned value has exactly 10 digits
    if (cleanedValue.length <= 10) {
      setError("");
    } else {
      setError("Mobile number should be 10 digits");
    }
  };

  const handleMobileNumberChangea = (event) => {
    const value = event.target.value;
    // Remove non-digit characters from the input
    const cleanedValue = value.replace(/\D/g, "");

    // Limit the input to 10 digits
    const limitedValue = cleanedValue.slice(0, 10);

    // Update the input field with the limited value
    setNumber(limitedValue);

    // Check if the cleaned value has exactly 10 digits
    if (cleanedValue.length <= 10) {
      setError("");
    } else {
      setError("Mobile number should be 10 digits");
    }
  };

  const handleColorChange = (event, itemId) => {
    const selectedColor = event.target.value;
    // Update the item with the selected color
    const updatedBagItems = bagItems.map((item) => {
      if (item.id === itemId) {
        return { ...item, selectedColor };
      }
      return item;
    });
    setBagItems(updatedBagItems); // Update the state with the modified bagItems
  };

  const handleSizeChange = (event, itemId) => {
    const selectedSize = event.target.value;
    // Update the item with the selected size
    const updatedBagItems = bagItems.map((item) => {
      if (item.id === itemId) {
        return { ...item, selectedSize };
      }
      return item;
    });
    setBagItems(updatedBagItems); // Update the state with the modified bagItems
  };

  return (
    <>
      <div className="m-4" style={{ borderBottom: "1px solid lightgrey" }}>
        <h6 className="back-to-home" onClick={handlePress}>
          Back To Home
        </h6>
        <br />
      </div>

      <div className="row cart-container">
        <div className="col-lg-6">
          <h2>Your Cart Items</h2>
          {loading ? (
            <p className="no-items-message">No items in the cart.</p>
          ) : bagItems.length > 0 ? (
            <ul className="cart-list">
              {bagItems.map((item) => (
                <li key={item.id} className="cart-item">
                  <img
                    src={item.imageUrl}
                    alt={item.title}
                    className="cart-image"
                  />
                  <div className="cart-details" style={{ display: "flex" }}>
                    <div style={{ padding: 30 }}>
                      <p className="item-name">{item.title}</p>
                      <p className="item-price" style={{ fontWeight: "bold" }}>
                        Price: ₹{item.price}
                      </p>
                      <p className="item-category">Category: {item.category}</p>
                      <p className="item-description">
                        Description: {item.detail}
                      </p>
                      <p className="item-stock" style={{ color: "black" }}>
                        Item Stock: {item.stock}KG
                      </p>
                    </div>
                    <div style={{ padding: 20 }}>
                      <div className="quantity-container">
                        <button
                          onClick={() => handleMinus(item)}
                          className="quantity-button"
                        >
                          -
                        </button>
                        <span className="quantity-text">{item.quantity}KG</span>
                        <button
                          onClick={() => handlePlus(item)}
                          className="quantity-button"
                        >
                          +
                        </button>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "80%",
                          marginTop: 5,
                          marginBottom: 5,
                        }}
                      >
                        {item.colors && item.colors.length > 0 && (
                          <div className="color-dropdown">
                            {item.colors && item.colors.length > 0 && (
                              <div className="color-dropdown">
                                <label htmlFor={`color-${item.id}`}>
                                  Color:
                                </label>
                                <select
                                  id={`color-${item.id}`}
                                  value={item.selectedColor} // Create a state for selectedColor if needed
                                  onChange={(e) =>
                                    handleColorChange(e, item.id)
                                  } // Implement the color change handler
                                >
                                  <option value=""></option>
                                  {item.colors.map((color, index) => (
                                    <option key={index} value={color}>
                                      {color}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                          </div>
                        )}
                        {item.sizes && item.sizes.length > 0 && (
                          <div className="size-dropdown">
                            <label htmlFor={`size-${item.id}`}>Size:</label>
                            <select
                              id={`size-${item.id}`}
                              value={item.selectedSize}
                              onChange={(e) => handleSizeChange(e, item.id)} // Implement the size change handler
                            >
                              <option value=""></option>
                              {item.sizes === "8x10 to 27x30"
                                ? [
                                    "8x10",
                                    "9x12",
                                    "11x14",
                                    "13x16",
                                    "16x20",
                                    "20x26",
                                    "27x30",
                                  ].map((size, index) => (
                                    <option key={index} value={size}>
                                      {size}
                                    </option>
                                  ))
                                : [
                                    "17x19",
                                    "19x21",
                                    "20x24",
                                    "24x36",
                                    "30x37",
                                    "30x40",
                                    "30x50",
                                    "40x50",
                                  ].map((size, index) => (
                                    <option key={index} value={size}>
                                      {size}
                                    </option>
                                  ))}
                            </select>
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor={`remark-${item.id}`}>
                          Remark (Optional):
                        </label>
                        <input
                          type="text"
                          id={`remark-${item.id}`}
                          name={`remark-${item.id}`}
                          placeholder="Add a remark (Optional)"
                          value={remarks[item.id] || ""}
                          onChange={(e) => handleRemarkChange(e, item.id)}
                        />
                      </div>
                      <button
                        className="place-order-button1"
                        onClick={() => removeBagItem(item)}
                      >
                        <b>Remove</b>
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className="no-items-message">No items in the cart.</p>
          )}
        </div>
        <div className="col-lg-6">
          <h3 style={{ color: "red" }}>
            Total Account : ₹{calculateTotalAmount()}{" "}
          </h3>
          <br></br>
          <div className="d-flex">
            <div className="form-group">
              <label htmlFor="username">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter your name"
                value={user.name}
                onChange={(e) => setName(e.target.value)}
                editable={false}
                disabled={true}
              />
            </div>
            <div className="form-group">
              <label htmlFor="username">Number:</label>
              <input
                type="number"
                id="number"
                name="number"
                placeholder="Enter your Mobile number"
                value={user.number}
                onChange={handleMobileNumberChange}
                editable={false}
                disabled={true}
              />
            </div>
          </div>

          <div className="d-flex" style={{ justifyContent: "space-between" }}>
            <div className="form-group">
              <label htmlFor="username">City:</label>
              <input
                type="text"
                id="add"
                name="add"
                value={user.city}
                editable={false}
                disabled={true}
              />
            </div>
            <div className="form-group">
              <label htmlFor="distributer">Distributer:</label>
              <input
                type="text"
                id="distributer"
                name="distributer"
                placeholder="Enter your Mobile number"
                value={user.distributer}
                editable={false}
                disabled={true}
              />
            </div>

            <div className="form-group">
              <label htmlFor="rso">RSO:</label>
              <input
                type="text"
                id="rso"
                name="rso"
                placeholder="Enter your Mobile number"
                value={user.rso}
                editable={false}
                disabled={true}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="username">Address:</label>
            <input
              type="text"
              id="add"
              name="add"
              placeholder="Enter your Address"
              value={user.address}
              editable={false}
              disabled={true}
            />
          </div>
          <div className="form-group">
            <label htmlFor="username">Alternate Number (Optional):</label>
            <input
              type="text"
              id="alternateNumber"
              name="alternateNumber"
              placeholder="Alternate Number (Optional)"
              value={number}
              onChange={handleMobileNumberChangea}
            />
          </div>

          <button
            className="place-order-button"
            onClick={() => handleOrderPlacement()}
          >
            <b>Place Order</b>
          </button>
        </div>
      </div>
    </>
  );
}
