import React, { useEffect, useState } from "react";
import {
  addDoc,
  collection,
  serverTimestamp,
  getDocs,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { db, app } from "../../../Firebase";
import Sidebar from "./SideBar";
import DBRHeader from "../../SubComponent/DBRHeader";
import "../Style.css";
import AsyncStorage from "@react-native-async-storage/async-storage";
export default function Product() {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);
  const [userData, setUserData] = useState("");

  useEffect(() => {
    // Check if the user is already logged in (stored in AsyncStorage)
    AsyncStorage.getItem("userData").then((userData) => {
      if (userData) {
        // If user data exists, parse it and set the user profile
        const parsedUserData = JSON.parse(userData);
        console.log("User Data:", parsedUserData.email);
        setUserData(parsedUserData.email);
      }
    });
  }, []);
  useEffect(() => {
    getAllCategories();
  }, []);

  const handleQuantityChange = (event, title, stock) => {
    let newQuantity = parseInt(event.target.value, 10) || 0;
    // Ensure the new quantity is within the range of 1 and the available stock
    newQuantity = Math.max(1, Math.min(newQuantity, stock));
    setCategories((prevCategories) =>
      prevCategories.map((category) =>
        category.title === title
          ? { ...category, quantity: newQuantity }
          : category
      )
    );
  };

  const handleAddToOrder = (selectedProduct) => {
    const productExists = selectedProducts.find(
      (product) => product.title === selectedProduct.title
    );

    if (productExists) {
      const updatedProducts = selectedProducts.filter(
        (product) => product.title !== selectedProduct.title
      );
      setSelectedProducts(updatedProducts);
    } else {
      setSelectedProducts([...selectedProducts, selectedProduct]);
    }

    toggleSelection(selectedProduct.title);
  };

  const toggleSelection = (title) => {
    if (selectedItems.includes(title)) {
      setSelectedItems(selectedItems.filter((item) => item !== title));
    } else {
      setSelectedItems([...selectedItems, title]);
    }
  };

  const isItemSelected = (title) => selectedItems.includes(title);

  const handleSubmitOrder = async (e) => {
    e.preventDefault();
    try {
      // Check if there are selected products
      if (selectedProducts.length === 0) {
        alert("Product Not Selected");
        console.log("No selected products.");
        return;
      }

      // Calculate the total price for each selected product
      const productsWithTotalPrice = selectedProducts.map(
        ({ title, quantity, price }) => ({
          title,
          quantity,
          totalPrice: quantity * price,
        })
      );

      // Calculate the grand total
      const grandTotal = productsWithTotalPrice.reduce(
        (total, product) => total + product.totalPrice,
        0
      );

      // Create a new order document
      const newOrder = {
        timestamp: serverTimestamp(),
        products: productsWithTotalPrice,
        grandTotal,
        userEmail: userData,
        status: "Pending",
      };

      // Add the new order to the 'dirOrder' collection
      const orderRef = await addDoc(collection(db, "dirOrder"), newOrder);

      // Clear the selectedProducts array and selectedItems after submitting the order
      setSelectedProducts([]);
      setSelectedItems([]);

      // Show confirmation alert
      const isConfirmed = window.confirm(
        `Order submitted successfully!\nOrder ID: ${orderRef.id}\nGrand Total: ₹${grandTotal}`
      );

      if (isConfirmed) {
        // User clicked 'OK' in the confirmation alert
        console.log("Order confirmed!");
        alert("Order submitted successfully!");
      } else {
        // User clicked 'Cancel' in the confirmation alert
        console.log("Order canceled!");
        alert("Order canceled! ");
      }
    } catch (error) {
      console.error("Error submitting order:", error);
    }
  };

  const getAllCategories = async () => {
    try {
      const categoriesCollection = collection(db, "items");
      const categoriesSnapshot = await getDocs(categoriesCollection);
      const categoriesData = categoriesSnapshot.docs.map((doc) => {
        const productData = doc.data();
        return { ...productData, quantity: 0 };
      });
      setCategories(categoriesData);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching all categories:", error);
    }
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <div className="row" style={{ overflow: "hidden" }}>
        <div className="col-md-3">
          <Sidebar />
        </div>
        <main className="col-md-9">
          <DBRHeader />
          <br />
          <div className="mt-2">
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h1
                style={{
                  fontSize: 30,
                  fontWeight: "bold",
                  fontStyle: "italic",
                  marginTop: 10,
                  marginBottom: 20,
                  marginRight: 20,
                  color: "blue",
                }}
              >
                Create Products Order
              </h1>
              <button
                type="button"
                style={{
                  height: 35,
                  backgroundColor: "green",
                  width: "20%",
                  marginTop: 15,
                  marginRight: 20,
                  borderRadius: 20,
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  fontSize: 16,
                  fontWeight: "500",
                  border: "2px solid green",
                }}
                onClick={handleSubmitOrder}
              >
                Submit Order
              </button>
            </div>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <table className="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Image</th>
                    <th>Title</th>
                    <th>Category</th>
                    <th>Sizes</th>
                    <th>Colours</th>
                    <th>Price</th>
                    <th>Stock</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {categories.map((category) => (
                    <tr
                      key={category.title}
                      style={{
                        backgroundColor: isItemSelected(category.title)
                          ? "white"
                          : "",
                      }}
                    >
                      <td>
                        <img
                          src={category.imageUrl}
                          alt={category.title}
                          style={{
                            width: "70px",
                            height: "70px",
                            borderRadius: "5px",
                          }}
                        />
                      </td>
                      <td>{category.title}</td>
                      <td>{category.category}</td>
                      <td>
                        {category.sizes &&
                          Array.isArray(category.sizes) &&
                          category.sizes.map((size, index) => (
                            <React.Fragment key={index}>
                              {size}
                              {index !== category.sizes.length - 1 && (
                                <br />
                              )}{" "}
                            </React.Fragment>
                          ))}
                      </td>
                      <td>
                        {category.colors &&
                          Array.isArray(category.colors) &&
                          category.colors.map((color, index) => (
                            <React.Fragment key={index}>
                              {color}
                              {index !== category.colors.length - 1 && (
                                <br />
                              )}{" "}
                            </React.Fragment>
                          ))}
                      </td>
                      <td>₹{category.price}</td>
                      <td>{category.stock}</td>
                      <td>
                        <input
                          style={{ width: 80 }}
                          type="number"
                          placeholder="Enter Quantity"
                          name={`quantity_${category.title}`}
                          value={category.quantity || 0}
                          onChange={(e) =>
                            handleQuantityChange(
                              e,
                              category.title,
                              category.stock
                            )
                          }
                        />
                        <b>KG</b>
                      </td>
                      <td>
                        {category.quantity > 0 && (
                          <button
                            className={`btn btn-info m-2 ${
                              isItemSelected(category.title) ? "selected" : ""
                            }`}
                            onClick={() => handleAddToOrder(category)}
                            style={{
                              backgroundColor: isItemSelected(category.title)
                                ? "white"
                                : "",
                            }}
                          >
                            Add
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}
