import React, { useState, useEffect } from "react";
import { Carousel, Container, Row, Col, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./file.css";
import Contact from "../Home/Contact";
import { useNavigate } from "react-router-dom";

import "./DownloadApp.css"; // Import your CSS styles here
import AppStoreImage from "../../img/WhatsApp Image 2023-08-26 at 17.32.15.jpeg"; // Replace with your app store image URL
import GooglePlayImage from "../../img/7812583b-8ef9-42b9-bf73-5b477c0fa513.jfif";
import GooglePlay from "../../img/WhatsApp Image 2023-08-26 at 17.32.17 (2).jpeg";
import App from "../../img/b66ec47c-53fd-40c9-b097-626a248fe75c.jfif"; // Replace with your app store image URL
import Google from "../../img/WhatsApp Image 2023-08-26 at 17.32.16 (1).jpeg";
import { collection, getDocs } from "firebase/firestore"; // Import Firestore functions

import { db } from "../../Firebase.js";

export default function Home() {
  const [itemList, setItemList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [lastUploadedVideo, setLastUploadedVideo] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsDisplayed, setRowsDisplayed] = useState(1);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [rowsDisplayed2, setRowsDisplayed2] = useState(1);

  const [imageHeight, setImageHeight] = useState(600);
  const [slide, setSlide] = useState([]);

  useEffect(() => {
    getAllCategories();
  }, []);

  const getAllCategories = async () => {
    try {
      const categoriesCollection = collection(db, "categories"); // Reference to the 'categories' collection
      const categoriesSnapshot = await getDocs(categoriesCollection);
      const categoriesData = categoriesSnapshot.docs.map((doc) => doc.data());
      setCategoriesData(categoriesData);
    } catch (error) {
      console.log("Error fetching all categories:", error);
    }
  };

  const history = useNavigate();
  useEffect(() => {
    const fetchItems = async () => {
      try {
        const itemsCollection = collection(db, "items"); // Reference to the 'items' collection
        const itemsSnapshot = await getDocs(itemsCollection);
        const itemsData = itemsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setItemList(itemsData);
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    };

    fetchItems();
  }, []);

  useEffect(() => {
    const fetchSlide = async () => {
      try {
        const itemsCollection = collection(db, "sliders"); // Reference to the 'sliders' collection
        const itemsSnapshot = await getDocs(itemsCollection);

        // Map the data from the snapshot and set it in the state
        const itemsData = itemsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSlide(itemsData);

        if (itemsData.length > 0) {
          // Calculate the height of the first image in the slide
          const img = new Image();
          img.onload = function () {
            setImageHeight(img.height);
          };
          img.src = itemsData[0].imageUrl; // Use the first image in the fetched data
        }

        console.log("Slide data:", itemsData); // Log the data
      } catch (error) {
        console.error("Error fetching slide data:", error);
      }
    };

    fetchSlide();
  }, []);

  useEffect(() => {
    const filtered = itemList.filter((item) =>
      item.title.toLowerCase().includes(searchInput.toLowerCase())
    );

    setFilteredItems(filtered);
  }, [searchInput, itemList]);

  const handlePress = (itemId) => {
    history("/products");
  };

  const handleCate = (title) => {
    history(`/products?category=${title}`);
  };

  const loadMore = () => {
    setCurrentPage(currentPage + 1);
    setRowsDisplayed(rowsDisplayed + 1); // Increment the number of rows displayed
  };
  const loadMore2 = () => {
    setCurrentPage2(currentPage2 + 1);
    setRowsDisplayed2(rowsDisplayed2 + 1); // Increment the number of rows displayed
  };

  // Fetch last uploaded video
  useEffect(() => {
    const fetchLastUploadedVideo = async () => {
      try {
        const videosCollection = collection(db, "videos");
        const videosSnapshot = await getDocs(videosCollection);
        const videosData = videosSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        if (videosData.length > 0) {
          const lastUploadedVideo = videosData[videosData.length - 1];
          // Use the last uploaded video's URL to display it in the video player section
          setLastUploadedVideo(lastUploadedVideo);
        }
      } catch (error) {
        console.error("Error fetching last uploaded video:", error);
      }
    };

    fetchLastUploadedVideo();
  }, []);

  return (
    <>
      <div style={{ overflowX: "hidden" }}>
        <Row>
          {/* Col-8 for Carousel */}
          <Col md={8} style={{ height: "500px", overflow: "hidden" }}>
            <Carousel style={{ height: imageHeight + "px" }}>
              {slide.map((item) => (
                <Carousel.Item key={item.id}>
                  <div
                    style={{ position: "relative", height: imageHeight + "px" }}
                  >
                    <img
                      className="slide d-block w-100"
                      src={item.imageUrl}
                      alt={item.title}
                      style={{ height: "100%", objectFit: "contain" }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                      }}
                    ></div>
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>

          {/* Col-4 for Video Player */}
          <Col
            md={4}
            style={{
              height: "500px",
              backgroundColor: "aliceblue",
            }}
          >
            {lastUploadedVideo && (
              <div style={{ height: "100%" }}>
                <video
                  src={lastUploadedVideo.VideoUrl}
                  controls
                  autoPlay
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>
            )}
          </Col>
        </Row>

        <div className="container-pro">
          <h2>Our Categories</h2>
          {Array.from({ length: rowsDisplayed }).map((_, index) => (
            <div key={index} className="card-container1">
              {categoriesData
                .slice(index * 5, (index + 1) * 5)
                .map((category) => (
                  <div
                    key={category.id}
                    className="card1"
                    onClick={() => handleCate(category.title)}
                  >
                    <div>
                      <img
                        src={category.imageUrl}
                        alt={category.title}
                        className="card-image1"
                      />
                    </div>
                    <div className="card-details1">
                      <h2 className="card-title1">{category.title}</h2>
                      <p className="card-detail1">{category.detail}</p>
                    </div>
                  </div>
                ))}
            </div>
          ))}

          {currentPage * 5 < categoriesData.length && (
            <p
              onClick={() => loadMore()}
              style={{ color: "red", cursor: "pointer" }}
            >
              Load More
            </p>
          )}
        </div>

        <div className="container-pro">
          <h2>Our Products</h2>
          {[...Array(rowsDisplayed2)].map((_, rowIndex) => (
            <div
              key={rowIndex}
              className="card-container"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              {filteredItems
                .slice(rowIndex * 5, (rowIndex + 1) * 5)
                .map((item) => (
                  <div key={item.id} className="cardItem">
                    <img
                      src={item.imageUrl}
                      alt={item.title}
                      className="card-image"
                    />
                    <hr></hr>
                    <div className="card-details">
                      <h2 className="card-title">{item.title}</h2>
                      <p className="card-detail">{item.detail}</p>
                      <button
                        onClick={() => handlePress()}
                        className="card-button"
                      >
                        View Details
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          ))}

          {currentPage2 * 5 < filteredItems.length && (
            <p
              onClick={() => loadMore2()}
              style={{ color: "red", cursor: "pointer" }}
            >
              Load More
            </p>
          )}
        </div>
        <div>
          <div className="download-app-container">
            <h2>Download Our App</h2>
            <p>
              Get the best shopping experience on your mobile device. Download
              our app now!
            </p>
            <div className="app-buttons">
              <a
                href="https://play.google.com/store/apps/details?id=com.shoppinhubtechies&pli=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={AppStoreImage}
                  alt="App Store"
                  className="app-store-image1"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.shoppinhubtechies&pli=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={Google}
                  alt="App Store"
                  className="app-store-image2"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.shoppinhubtechies&pli=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={GooglePlayImage}
                  alt="App Store"
                  className="app-store-image3"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.shoppinhubtechies&pli=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={App} alt="App Store" className="app-store-image2" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.shoppinhubtechies&pli=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={GooglePlay}
                  alt="App Store"
                  className="app-store-image1"
                />
              </a>
            </div>
            <a
              href="https://play.google.com/store/apps/details?id=com.shoppinhubtechies&pli=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button src="" className="btnApp">
                <b>Download Now</b>
              </button>
            </a>
          </div>
        </div>
        <Contact />
      </div>
    </>
  );
}
