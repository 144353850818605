import React, {useEffect, useState} from "react";
import SideBar from "./SideBar";
import { useNavigate } from "react-router-dom";
import {
  FaHome,
  FaShoppingCart,
  FaInfoCircle,
  FaEnvelope,
  FaHeart,
  FaBellSlash,
  FaBell
} from "react-icons/fa";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  getFirestore,
  collection,
  addDoc,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore"; // Import Firestore functions
import { db } from "../../../Firebase";
import Nav from "react-bootstrap/Nav";
import {Modal, Button } from "react-bootstrap";
import logoImage from '../../../img/LOGO.jpeg'
import RSOHeader from "../../SubComponent/RSOHeader"
const RSOHome = () => {
  const history = useNavigate();
  const [notification, setNotification] = useState([]);
  const [showModal, setShowModal] = useState(false);
 
  const handleOpenModal = () => {
    setShowModal(true);
  };

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    fetchNotification();
  }, []);

  const fetchNotification = async () => {
    try {
      const UserData = await AsyncStorage.getItem("userData");
      const parseData = JSON.parse(UserData);
      const emailId = parseData.name;

      const itemsCollection = collection(db, "notifications");
      const itemsSnapshot = await getDocs(itemsCollection);
      if (itemsSnapshot.docs.length === 0) {
        console.log("No notifications found.");
        // Handle accordingly, e.g., set an empty array or display a message.
      } else {
        // Continue processing data.
      }
    
      // Filter orders by user email
      const ordersData = itemsSnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((order) => order.rso === emailId);
    console.log("Notification",ordersData);
     
      setNotification(ordersData);
      console.log("Notification556",notification);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };
 
  return (
    <>
      <div style={{ overflow: "hidden" }}>
        <div className="row" style={{ overflow: "hidden" }}>
          {/* Sidebar (left side) */}
          <div className="col-md-3">
            <SideBar />
          </div>

          {/* Content (right side) */}
          <main className="col-md-9">
          <RSOHeader/>
            <h2 className="my-4"> RSO / Dashboard</h2>
            <div className="row">
              {/* Card 1 */}
              <div className="col-lg-5 col-md-6 col-sm-12 mb-4">
                <div
                  className="card shadow"
                  style={{ width: "100%", height: "180px" }}
                >
                  <div className="card-body">
                    <h5 className="card-title">All Orders</h5>
                    <p className="card-text">
                      <b>You have to see All Orders Click on this button.</b>
                    </p>
                    <button
                      className="btn btn-primary"
                      onClick={() => history("/rsoOrder")}
                    >
                      View More
                    </button>
                  </div>
                </div>
              </div>

              {/* Card 2 */}
              <div className="col-lg-5 col-md-6 col-sm-12 mb-4 ">
                <div
                  className="card shadow"
                  style={{ width: "100%", height: "180px" }}
                >
                  <div className="card-body">
                    <h5 className="card-title">My Distributer</h5>
                    <p className="card-text">
                      <b>You have to see All users Click on this button.</b>
                    </p>
                    <button
                      className="btn btn-primary"
                      onClick={() => history("/dbrlist")}
                    >
                      View More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>{" "}


      <Modal show={showModal} onHide={handleCloseModal}>
  <Modal.Header closeButton>
    <Modal.Title>All Notifications</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {/* Display all notifications dynamically */}
    {notification && notification.length > 0 ? (
      notification.map((notif, index) => (
        <div key={index} style={{ border: '1px solid lightgrey',borderRadius:2, padding:10, margin:10}}>
          <h4>{notif.subject}</h4>
          <p style={{color:'green'}}>From: {notif.fromid}</p>
          <p>Message: {notif.message}</p>
          {/* Add other fields as needed */}
          <small>Sent at: {new Date(notif.timestamp?.seconds * 1000).toLocaleString()}</small>
        </div>
      ))
    ) : (
      <p>No notifications found.</p>
    )}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleCloseModal}>
      Close
    </Button>

  </Modal.Footer>
</Modal>
    </>
  );
};

export default RSOHome;
