import React, { useEffect, useState } from "react";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import { NavLink, useNavigate } from "react-router-dom";
import AsyncStorage from "@react-native-async-storage/async-storage";

const SideBar = () => {
  const [userData, setUserData] = useState([]);

  const history = useNavigate();
  const handleLogout = async () => {
    await AsyncStorage.removeItem("userData");
    history("/");
  };

  useEffect(() => {
    // Check if the user is already logged in (stored in AsyncStorage)
    AsyncStorage.getItem("userData").then((userData) => {
      if (userData) {
        // If user data exists, parse it and set the user profile
        const parsedUserData = JSON.parse(userData);
        setUserData(parsedUserData);
      }
    });
  }, 1000);

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        overflow: "hidden",
        position: "fixed",
      }}
    >
      <CDBSidebar textColor="#fff" backgroundColor="#333">
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          <a
            href="/"
            className="text-decoration-none"
            style={{ color: "inherit" }}
          >
            BIOPIN | AGROPLA
          </a>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            {userData && userData.type === "RSO" ? (
              <>
                <CDBSidebarMenuItem
                  icon="columns"
                  activeClassName="activeClicked"
                >
                  <NavLink to="/rsohome">Dashboard</NavLink>
                </CDBSidebarMenuItem>
                <NavLink exact to="/dbrlist" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="user">
                    Distributer
                  </CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/rsoorder" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="chart-line">
                    Orders
                  </CDBSidebarMenuItem>
                </NavLink>
              </>
            ) : (
              <>
                <CDBSidebarMenuItem
                  icon="columns"
                  activeClassName="activeClicked"
                >
                  <NavLink to="/dbrhome">Dashboard</NavLink>
                </CDBSidebarMenuItem>
                <NavLink exact to="/rsolist" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="user">RSO</CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/dbrorder" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="chart-line">
                    Orders
                  </CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/orderPlace" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="user">
                    Create Order
                  </CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/disOrder" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="user">My Orders</CDBSidebarMenuItem>
                </NavLink>
              </>
            )}

            <NavLink exact to="" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="sign-out-alt" onClick={handleLogout}>
                Logout
              </CDBSidebarMenuItem>
            </NavLink>
          </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarFooter style={{ textAlign: "center" }}>
          <div
            style={{
              padding: "20px 5px",
            }}
          >
            - BIOPIN | AGROPLA -
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
    </div>
  );
};

export default SideBar;
