import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import "./file.css";
import { db } from "../../Firebase.js";
import { useNavigate, useLocation, useParams } from "react-router-dom";

export default function AllItems() {
  const [itemList, setItemList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const location = useLocation();
  const category = new URLSearchParams(location.search).get("category");
  console.log("category:", category);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const itemsCollection = collection(db, "items");
        const itemsSnapshot = await getDocs(itemsCollection);
        const itemsData = itemsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setItemList(itemsData);
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    };

    fetchItems();
  }, []);

  useEffect(() => {
    const filtered = itemList.filter((item) =>
      item.title.toLowerCase().includes(searchInput.toLowerCase())
    );

    setFilteredItems(filtered);
  }, [searchInput, itemList]);

  const addToCart = (item) => {
    // Check if the user is authenticated (you can use Firebase Auth for this)
    const user = JSON.parse(localStorage.getItem("userData"));
    console.log(user);
    if (!user) {
      // If the user is not logged in, show an alert and navigate to the login page
      alert("Please log in to add items to your cart.");
      // Redirect the user to the login page (replace '/login' with your actual login route)
      navigate("/login");
      return;
    }

    // If the user is logged in, check if the item is already in the cart
    const cartItems = JSON.parse(localStorage.getItem("cart")) || [];
    const itemAlreadyInCart = cartItems.some(
      (cartItem) => cartItem.id === item.id
    );
    if (itemAlreadyInCart) {
      alert("This item is already in your cart.");
      return;
    }

    // Add the item to the cart
    cartItems.push({ ...item, quantity: 1 });

    // Store the modified cart items in localStorage
    localStorage.setItem("cart", JSON.stringify(cartItems));

    // Set the selected product ID in the state
    setSelectedProduct(item.id);

    // Show an alert here after the item is added to the cart
    alert("Item added to cart successfully");
  };

  // In your component, you can use useEffect to monitor changes in the selectedProduct state
  useEffect(() => {
    if (selectedProduct !== null) {
      // Display your alert or modal here
      // This ensures that it's displayed after the state update
      // and any potential localStorage updates are complete.
    }
  }, [selectedProduct]);

  return (
    <div className="container-pro">
      <h2 style={{ color: "blue" }}>Our Products</h2>
      <div className="top">
        <h6>
          You can use the search bar to find products based on your specific
          requirements.
        </h6>
        <input
          placeholder="Search here..."
          className="input"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
      </div>
      <h6>
        Everything you need is just a click away in our 'All Products' category.
        Our store offers a vast range of all products to cater to your needs
      </h6>
      <div className="card-container">
        {filteredItems
          .filter(
            (item) =>
              !category ||
              item.category.toLowerCase().includes(category.toLowerCase())
          )
          .map((item) => (
            <div key={item.id} className="cardItem">
              <img
                src={item.imageUrl}
                alt={item.title}
                className="card-image"
              />
              <hr></hr>
              <div className="card-details">
                <h3 className="card-title">{item.title}</h3>
                <p style={{ fontWeight: "600", fontSize: 18 }}>
                  {item.category}
                </p>
                <p className="card-detail">{item.detail}</p>
                <p className="card-detail " style={{ color: "green" }}>
                  Price : ${item.price}
                </p>
                <p className="card-detail" style={{ color: "blue" }}>
                  Available : {item.stock}KG
                </p>
                <button onClick={() => addToCart(item)} className="card-button">
                  <b>Add To Cart</b>
                </button>
              </div>
            </div>
          ))}
      </div>
      {selectedProduct && (
        <div className="modal">
          <div className="modal-content">
            <h2>{selectedProduct.title}</h2>
            <p>{selectedProduct.detail}</p>
            <p style={{ color: "green" }}>Price: ${selectedProduct.price}</p>
            <p style={{ color: "blue" }}>
              Available: {selectedProduct.stock}KG
            </p>
            <button
              onClick={() => setSelectedProduct(null)}
              className="card-button"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
