import React from 'react'

import Routes from './Component/Routes.js'
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
<>

<Routes/>
</>
  );
}

export default App;
