import React from "react";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import { NavLink, useNavigate } from "react-router-dom";
import AsyncStorage from "@react-native-async-storage/async-storage";

const Sidebar = () => {
  const history = useNavigate();
  const handleLogout = async () => {
    await AsyncStorage.removeItem("userData");
    history("/");
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        overflow: "hidden",
        position: "fixed",
      }}
    >
      <CDBSidebar textColor="#fff" backgroundColor="#333">
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          <a
            href="/"
            className="text-decoration-none"
            style={{ color: "inherit" }}
          >
            BIOPIN | AGROPLA
          </a>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            <CDBSidebarMenuItem icon="columns" activeClassName="activeClicked">
              <NavLink to="/admin">Dashboard</NavLink>
            </CDBSidebarMenuItem>

            <NavLink exact to="/allcategory" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="table">Category</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/Addrangecolor" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="table">
                Add Size / Color
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/addproduct" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="exclamation-circle">
                Product
              </CDBSidebarMenuItem>
            </NavLink>

            <NavLink exact to="/orders" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="chart-line">
                Retailer Orders
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/orderDis" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="chart-line">
                Distributor Orders
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/users" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="user">Retailer</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/DRBR" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="user">Distributor</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/RSO" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="user">RSO</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/slider" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="user">Add Slider</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/video" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="user">Add Video</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/Allcontact" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="user">
                Contact Details
              </CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="sign-out-alt" onClick={handleLogout}>
                Logout
              </CDBSidebarMenuItem>
            </NavLink>
          </CDBSidebarMenu>
        </CDBSidebarContent>

        <CDBSidebarFooter style={{ textAlign: "center" }}>
          <div
            style={{
              padding: "20px 5px",
            }}
          >
            - BIOPIN | AGROPLA -
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
    </div>
  );
};

export default Sidebar;
