import Sidebar from "../Sidebar";
import "../Style.css";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  addDoc,
  updateDoc,
  query,
  where,
} from "firebase/firestore"; // Import Firestore functions
import React, { useEffect, useState } from "react";
import { db, app } from "../../../Firebase";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import Header from "../../SubComponent/Header";

export default function Produt() {
  const storage = getStorage(app);

  const [categories, setCategories] = useState([]);
  const [catego, setCatego] = useState([]);
  const [c, setC] = useState([]);
  const [s, setS] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const [imageUrl, setImageUrl] = useState("");
  const [imageFile, setImageFile] = useState(null);

  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [detail, setDetail] = useState("");
  const [price, setPrice] = useState("");
  const [stock, setStock] = useState("");
  const [sizes, setSizes] = useState("");
  const [colors, setColors] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);

  const handleSizeChange = (event) => {
    const selectedSize = event.target.value;

    setSelectedSizes((prevSizes) =>
      prevSizes.includes(selectedSize)
        ? prevSizes.filter((size) => size !== selectedSize)
        : [...prevSizes, selectedSize]
    );
    setSizes((prevSizes) => {
      if (prevSizes.includes(selectedSize)) {
        // If already selected, remove it
        return prevSizes.filter((size) => size !== selectedSize);
      } else {
        // If not selected, add it
        return [...prevSizes, selectedSize];
      }
    });
  };

  const handleColorChange = (event) => {
    const selectedColor = event.target.value;

    setSelectedColors((prevColors) =>
      prevColors.includes(selectedColor)
        ? prevColors.filter((color) => color !== selectedColor)
        : [...prevColors, selectedColor]
    );
    setColors((prevColors) => {
      if (prevColors.includes(selectedColor)) {
        return prevColors.filter((color) => color !== selectedColor);
      } else {
        return [...prevColors, selectedColor];
      }
    });
  };

  useEffect(() => {
    fetchColor();
    fetchSize();
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const categoriesCollection = collection(db, "categories");

      const snapshot = await getDocs(categoriesCollection);
      const categoriesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCatego(categoriesData);
      console.log("caetrogy", catego);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchColor = async () => {
    try {
      const categoriesCollection = collection(db, "color");

      const snapshot = await getDocs(categoriesCollection);
      const categoriesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setC(categoriesData);
      console.log("color", categoriesData);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const fetchSize = async () => {
    try {
      const categoriesCollection = collection(db, "size");

      const snapshot = await getDocs(categoriesCollection);
      const categoriesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setS(categoriesData);
      console.log("size", categoriesData);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileSize = file.size;
      const maxSize = 20 * 1024 * 1024; // 20MB maximum size for image

      if (fileSize > maxSize) {
        alert("File size exceeds the limit (20MB max).");
        event.target.value = null;
      } else {
        setImageFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImageUrl(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleAddCategory = async () => {
    console.log("Selected Sizes:", selectedSizes);
    console.log("Selected Colors:", selectedColors);
    if (!imageFile) {
      alert("Please select an image.");
      return;
    } else if (!title || !detail) {
      alert("Please enter title and detail.");
      return;
    }

    try {
      setIsLoading(true);

      const storageRef = ref(storage, `items/${imageFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, imageFile);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Handle progress if needed
        },
        async (error) => {
          setIsLoading(false);
          console.error("Error uploading:", error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

          const newProduct = {
            imageUrl: downloadURL,
            title,
            sizes: selectedSizes,
            colors: selectedColors,
            detail,
            price,
            stock,
            category,
            createdAt: Date.now(),
          };

          await addDoc(collection(db, "items"), newProduct);

          setIsLoading(false);
          closeModal();
          alert("Product added successfully!");
          window.location.reload();

          setImageFile(null);
          setImageUrl("");
          setTitle("");
          setDetail("");
        }
      );
    } catch (error) {
      setIsLoading(false);
      console.error("Error adding product:", error);
    }
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const openUpdateModal = (title) => {
    const selectedProduct = categories.find(
      (category) => category.title === title
    );

    if (selectedProduct) {
      setTitle(selectedProduct.title);
      setCategory(selectedProduct.category);
      setDetail(selectedProduct.detail);
      setPrice(selectedProduct.price);
      setStock(selectedProduct.stock);
      setSizes(selectedProduct.sizes);
      setColors(selectedProduct.colors || []);
      setShowUpdateModal(true);
    }
  };

  const closeUpdateModal = () => {
    // Reset state variables to their initial values or empty values
    setTitle("");
    setCategory("");
    setDetail("");
    setPrice(0);
    setStock(0);
    setSizes([]);
    setColors([]);
    setShowUpdateModal(false);
  };

  const getAllCategories = async () => {
    try {
      const categoriesCollection = collection(db, "items");
      const categoriesSnapshot = await getDocs(categoriesCollection);
      const categoriesData = categoriesSnapshot.docs.map((doc) => doc.data());
      setCategories(categoriesData);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching all categories:", error);
    }
  };

  const deleteCategory = async (title) => {
    try {
      const categoriesCollection = collection(db, "items");
      const querySnapshot = await getDocs(categoriesCollection);

      for (const categoryDoc of querySnapshot.docs) {
        const categoryData = categoryDoc.data();
        if (categoryData.title === title) {
          await deleteDoc(doc(db, "items", categoryDoc.id)); // Delete the category document
          console.log("Product deleted:", title);
        }
      }

      // Fetch updated category list after deletion loop finishes
      getAllCategories();
      alert("Product deleted successfully!");
    } catch (error) {
      console.log("Error deleting Product:", error);
    }
  };

  const handleUpdateProduct = async (productTitle) => {
    const updatedTitle = title;
    const updatedSizes = Array.from(new Set([...sizes, ...selectedSizes]));
    const updatedColors = Array.from(new Set([...colors, ...selectedColors]));
    const updatedDetail = detail;
    const updatedPrice = price;
    const updatedStock = stock;
    const updatedCategory = category;

    if (
      !updatedTitle ||
      !updatedDetail ||
      !updatedPrice ||
      !updatedStock ||
      !updatedCategory
    ) {
      alert("Please fill in all fields");
      return;
    }

    try {
      setIsLoading(true);

      const itemsCollection = collection(db, "items");
      const querySnapshot = await getDocs(
        query(itemsCollection, where("title", "==", productTitle))
      );

      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        const updateData = {
          title: updatedTitle,
          sizes: updatedSizes,
          colors: updatedColors,
          detail: updatedDetail,
          price: updatedPrice,
          stock: updatedStock,
          category: updatedCategory,
        };

        console.log("Updating with data:", updateData);

        await updateDoc(doc.ref, updateData);

        alert("Product updated successfully");
        closeUpdateModal();
        // Update local state with the new data
        setCategories((prevCategories) =>
          prevCategories.map((category) =>
            category.title === productTitle
              ? { ...category, ...updateData }
              : category
          )
        );

        setIsLoading(false);
      } else {
        alert("Product not found");
      }
    } catch (error) {
      console.error("Error updating product:", error);
      alert("Failed to update product. Please try again.");
      setIsLoading(false);
    }
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <div className="row" style={{ overflow: "hidden" }}>
        {/* Sidebar (left side) */}
        <div className="col-md-3">
          <Sidebar />
        </div>

        {/* Content (right side) */}
        <main className="col-md-9">
          <Header />
          <br />
          <div className="mt-5 pt-5">
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h1
                style={{
                  fontSize: 30,
                  fontWeight: "bold",
                  fontStyle: "italic",
                  marginTop: 10,
                  marginBottom: 20,
                  marginRight: 20,
                  color: "blue",
                }}
              >
                All Products
              </h1>
              <button
                style={{
                  height: 35,
                  backgroundColor: "green",
                  width: "20%",
                  marginTop: 15,
                  marginRight: 20,
                  borderRadius: 20,
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  fontSize: 16,
                  fontWeight: "500",
                  border: "2px solid green",
                }}
                onClick={openModal} // Step 4: Open the modal on button click
              >
                Add Product
              </button>
            </div>

            {loading ? (
              <p>Loading...</p>
            ) : (
              <table className="table">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Title</th>
                    <th>category</th>
                    <th>Sizes</th>
                    <th>Colours</th>
                    <th>Price</th>
                    <th>Stock</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {categories.map((category) => (
                    <tr key={category.title}>
                      <td>
                        <img
                          src={category.imageUrl}
                          alt={category.title}
                          style={{
                            width: "70px",
                            height: "70px",
                            borderRadius: "5px",
                          }}
                        />
                      </td>
                      <td>{category.title}</td>
                      <td>{category.category}</td>
                      <td>
                        {category.sizes &&
                          Array.isArray(category.sizes) &&
                          category.sizes.map((color, index) => (
                            <React.Fragment key={index}>
                              {color}
                              {index !== category.colors.length - 1 && (
                                <br />
                              )}{" "}
                            </React.Fragment>
                          ))}
                      </td>
                      <td>
                        {category.colors &&
                          Array.isArray(category.colors) &&
                          category.colors.map((color, index) => (
                            <React.Fragment key={index}>
                              {color}
                              {index !== category.colors.length - 1 && (
                                <br />
                              )}{" "}
                            </React.Fragment>
                          ))}
                      </td>
                      <td> ₹{category.price}</td>
                      <td>{category.stock}</td>
                      <td>
                        <button
                          onClick={() => openUpdateModal(category.title)}
                          className="btn btn-warning"
                        >
                          Update
                        </button>
                        <button
                          onClick={() => deleteCategory(category.title)}
                          className="btn btn-danger m-2"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </main>
      </div>
      {showModal && (
        <div className="modal-overlay" onClick={closeModal}>
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()}
            style={{
              backgroundColor: "white",
              width: "40%",
              marginTop: 100, // Adjust the width as needed
            }}
          >
            <div
              style={{
                maxHeight: "95vh",
                overflowY: "scroll",
                marginBottom: 100,
              }}
            >
              <h1>Add Product</h1>
              {/* Debugging: Check if imageUrl state is correctly updated */}
              <div className="image-upload">
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt="Category Preview"
                    style={{ height: 100, width: 100 }}
                  />
                )}
                <br></br>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                />
                <br></br>
              </div>
              <br></br>
              {/* <label>Select Category:</label> */}
              <select
                value={category} // Set the value attribute to the current value of category state
                onChange={(e) => setCategory(e.target.value)}
                style={{ width: "100%", height: "40px", marginBottom: 10 }}
              >
                {catego.map((categoryItem) => (
                  <option key={categoryItem.id} value={categoryItem.title}>
                    {categoryItem.title}
                  </option>
                ))}
              </select>
              <br></br>
              <input
                style={{ marginBottom: 10 }}
                type="text"
                placeholder="Enter Your Title"
                name="title"
                // Add value and onChange handlers to properly handle input changes
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <br></br>
              <input
                style={{ marginBottom: 10 }}
                type="text"
                placeholder="Enter Your Detail"
                name="detail"
                // Add value and onChange handlers to properly handle input changes
                value={detail}
                onChange={(e) => setDetail(e.target.value)}
              />
              <br></br>
              <h6 style={{ alignSelf: "flex-start" }}>Select Sizes :</h6>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {s.map((category) => (
                  <div key={category.detail}>
                    <label style={{ padding: 10 }}>
                      <input
                        type="checkbox"
                        value={category.detail}
                        checked={selectedSizes.includes(category.detail)}
                        onChange={handleSizeChange}
                        style={{ margin: 5 }}
                      />
                      {category.detail}
                    </label>
                  </div>
                ))}
              </div>
              <h6 style={{ alignSelf: "flex-start" }}>Select Colors :</h6>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {c.map((category) => (
                  <div key={category.color}>
                    <label style={{ padding: 10 }}>
                      <input
                        type="checkbox"
                        value={category.color}
                        checked={selectedColors.includes(category.color)}
                        onChange={handleColorChange}
                        style={{ margin: 5 }}
                      />
                      {category.color}
                    </label>
                  </div>
                ))}
              </div>
              <br></br>
              <input
                style={{ marginBottom: 10 }}
                type="number"
                placeholder="Enter Price"
                name="price"
                // Add value and onChange handlers to properly handle input changes
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
              <br></br>
              <input
                style={{ marginBottom: 10 }}
                type="number"
                placeholder="Enter item Stock"
                name="stock"
                // Add value and onChange handlers to properly handle input changes
                value={stock}
                onChange={(e) => setStock(e.target.value)}
              />
              <div style={{ flexDirection: "row" }}>
                {isLoading ? (
                  <div>Loading...</div>
                ) : (
                  <>
                    <button
                      className="auth-button2 m-3"
                      onClick={handleAddCategory}
                    >
                      Add Product
                    </button>
                    <button className="auth-button3 m-3" onClick={closeModal}>
                      Close
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {showUpdateModal && (
        <div className="modal-overlay" onClick={closeUpdateModal}>
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()}
            style={{
              backgroundColor: "white",
              width: "40%",
              marginTop: 100, // Adjust the width as needed
            }}
          >
            <div
              style={{
                maxHeight: "95vh",
                overflowY: "scroll",
                marginBottom: 100,
              }}
            >
              <h1>Update Product</h1>
              <br></br>
              <select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                style={{ width: "100%", height: "40px", marginBottom: 10 }}
              >
                <option value={category}>{category}</option>
                {catego.map((categoryItem) => (
                  <option key={categoryItem.id} value={categoryItem.title}>
                    {categoryItem.title}
                  </option>
                ))}
              </select>
              <br></br>
              <input
                style={{ marginBottom: 10 }}
                type="text"
                placeholder="Enter Your Title"
                name="title"
                // Add value and onChange handlers to properly handle input changes
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                disabled={true}
              />
              <br></br>
              <input
                style={{ marginBottom: 10 }}
                type="text"
                placeholder="Enter Your Detail"
                name="detail"
                // Add value and onChange handlers to properly handle input changes
                value={detail}
                onChange={(e) => setDetail(e.target.value)}
              />
              <br></br>
              <h6 style={{ alignSelf: "flex-start" }}>Select Sizes :</h6>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {s.map((category) => (
                  <div key={category.detail}>
                    <label style={{ padding: 10 }}>
                      <input
                        type="checkbox"
                        value={category.detail}
                        checked={
                          sizes.includes(category.detail) ||
                          selectedSizes.includes(category.detail)
                        }
                        onChange={handleSizeChange}
                        style={{ margin: 5 }}
                      />
                      {category.detail}
                    </label>
                  </div>
                ))}
              </div>
              <h6 style={{ alignSelf: "flex-start" }}>Select Colors :</h6>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {c.map((category) => (
                  <div key={category.color}>
                    <label style={{ padding: 10 }}>
                      <input
                        type="checkbox"
                        value={category.color}
                        checked={
                          colors.includes(category.color) ||
                          selectedColors.includes(category.color)
                        }
                        onChange={handleColorChange}
                        style={{ margin: 5 }}
                      />
                      {category.color}
                    </label>
                  </div>
                ))}
              </div>
              <br></br>
              <input
                style={{ marginBottom: 10 }}
                type="number"
                placeholder="Enter Price"
                name="price"
                // Add value and onChange handlers to properly handle input changes
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
              <br></br>
              <input
                style={{ marginBottom: 10 }}
                type="number"
                placeholder="Enter item Stock"
                name="stock"
                // Add value and onChange handlers to properly handle input changes
                value={stock}
                onChange={(e) => setStock(e.target.value)}
              />
              <div style={{ flexDirection: "row" }}>
                {isLoading ? (
                  <div>Loading...</div>
                ) : (
                  <>
                    <button
                      className="auth-button2 m-3"
                      onClick={() => handleUpdateProduct(title)}
                    >
                      Update Product
                    </button>
                    <button
                      className="auth-button3 m-3"
                      onClick={closeUpdateModal}
                    >
                      Close
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
