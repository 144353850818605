import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./Home/Home.js";
import About from "./Home/About.js";
import Products from "./Home/Product.js";
import Contact from "./Home/Contact.js";
import Navbar from "./SubComponent/Navbar.js";
import Footer from "./SubComponent/Footer.js";
import Admin from "./Admin/Admin.js";
import CategoryPage from "./Home/AllOrders.js";
import Category from "./Admin/SideMenu/Category.js";
import AddProduct from "./Admin/SideMenu/Product.js";
import Orders from "./Admin/SideMenu/Orders.js";
import User from "./Admin/SideMenu/User.js";
import RSO from "./Admin/SideMenu/RSO.js";
import AllContact from "./Admin/SideMenu/Contact.js";
import Slider from "./Admin/SideMenu/Slider.js";
import Videos from "./Admin/SideMenu/Videos.js";
import Cart from "./Home/Cart.js";
import Register from "./SubComponent/Register.js";
import Distributer from "./Admin/SideMenu/Distributer.js";
import RSOHome from "./Admin/Staffs/RSOHome.js";
import DBRHome from "./Admin/Staffs/DBRHome.js";
import RSOList from "./Admin/Staffs/RSOList.js";
import DBRList from "./Admin/Staffs/DBRList.js";
import RSOOrder from "./Admin/Staffs/RSOOrder.js";
import DBROrder from "./Admin/Staffs/DBROrder.js";
import AddRange from "./Admin/SideMenu/AddRange.js";
import DisOrder from "./Admin/Staffs/DisOrder.js";
import OrderPlace from "./Admin/Staffs/OrderPlace.js";
import OrderDis from "./Admin/SideMenu/OrderDis.js";
function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar />
                <Home />
                <Footer />
              </>
            }
          />
          <Route
            path="/about"
            element={
              <>
                <Navbar />
                <About />
                <Footer />
              </>
            }
          />
          <Route
            path="/register/:email"
            element={
              <>
                <Navbar />
                <Register />
                <Footer />
              </>
            }
          />
          <Route
            path="/products"
            element={
              <>
                <Navbar />
                <Products />
                <Footer />
              </>
            }
          />
          <Route
            path="/category"
            element={
              <>
                <Navbar />
                <CategoryPage />
                <Footer />
              </>
            }
          />
          <Route
            path="/contact"
            element={
              <>
                <Navbar />
                <Contact />
                <Footer />
              </>
            }
          />

          <Route
            path="/cart"
            element={
              <>
                <Cart />
              </>
            }
          />
          <Route
            path="/admin"
            element={
              <>
                <Admin />
              </>
            }
          />

          <Route
            path="/dbrhome"
            element={
              <>
                <DBRHome />
              </>
            }
          />
          <Route
            path="/rsohome"
            element={
              <>
                <RSOHome />
              </>
            }
          />

          <Route
            path="/disorder"
            element={
              <>
                <DisOrder />
              </>
            }
          />
          <Route
            path="/orderPlace"
            element={
              <>
                <OrderPlace />
              </>
            }
          />
          <Route
            path="/rsolist"
            element={
              <>
                <RSOList />
              </>
            }
          />
          <Route
            path="/dbrlist"
            element={
              <>
                <DBRList />
              </>
            }
          />
          <Route
            path="/rsoorder"
            element={
              <>
                <RSOOrder />
              </>
            }
          />
          <Route
            path="/dbrorder"
            element={
              <>
                <DBROrder />
              </>
            }
          />

          <Route path="/*" element={<Navigate to="/" />} />

          <Route path="/allcategory" element={<Category />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/Addrangecolor" element={<AddRange />} />
          <Route path="/orderDis" element={<OrderDis />} />

          <Route path="/addproduct" element={<AddProduct />} />
          <Route path="/users" element={<User />} />
          <Route path="/DRBR" element={<Distributer />} />
          <Route path="/RSO" element={<RSO />} />
          <Route path="/slider" element={<Slider />} />
          <Route path="/video" element={<Videos />} />
          <Route path="/Allcontact" element={<AllContact />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
