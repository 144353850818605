import React, { useState, useEffect } from "react";
import { db, app } from "../../../Firebase";
import Sidebar from "../Sidebar";
import Header from "../../SubComponent/Header";
import "../Admin.css";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  addDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

export default function Admin() {
  const storage = getStorage(app);

  const [orders, setOrders] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const [title, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const videosCollection = collection(db, "videos");
      const videosSnapshot = await getDocs(videosCollection);
      const videosData = videosSnapshot.docs.map((doc) => doc.data());
      setOrders(videosData);
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };

  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileSize = file.size;
      const maxSize = 20 * 1024 * 1024;

      if (fileSize > maxSize) {
        alert("File size exceeds the limit (20MB max).");
        event.target.value = null;
      } else {
        setVideoFile(file);
      }
    }
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleDelete = async (title) => {
    try {
      const videosCollection = collection(db, "videos");
      const querySnapshot = await getDocs(videosCollection);
      querySnapshot.forEach(async (videoDoc) => {
        const videoData = videoDoc.data();
        if (videoData.title === title) {
          await deleteDoc(doc(db, "videos", videoDoc.id));
          fetchOrders();
          alert("Video deleted successfully");
        }
      });
    } catch (error) {
      console.log("Error deleting video:", error);
    }
  };

  const handleAdd = async () => {
    if (!videoFile) {
      alert("Please select a video.");
    } else if (!title) {
      alert("Please enter title and details.");
    } else {
      try {
        setIsLoading(true);

        const storageRef = ref(storage, `videos/${videoFile.name}`);
        const uploadTask = uploadBytesResumable(storageRef, videoFile);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Handle progress if needed
          },
          (error) => {
            setIsLoading(false);
            console.error("Error uploading:", error);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

            const newVideo = {
              VideoUrl: downloadURL,
              title,
              createdAt: Date.now(),
            };

            await addDoc(collection(db, "videos"), newVideo);

            setIsLoading(false);
            closeModal();
            alert("Video added successfully!");
            window.location.reload();

            setVideoFile(null);
            setTitle("");
          }
        );
      } catch (error) {
        setIsLoading(false);
        console.error("Error adding Video:", error);
      }
    }
  };

  return (
    <div className="admin-page">
      <div className="row">
        <div className="col-md-3">
          <Sidebar />
        </div>
        <main className="col-md-9">
          <Header />
          <div className="mt-5 pt-5">
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h1
                style={{
                  fontSize: 30,
                  fontWeight: "bold",
                  fontStyle: "italic",
                  marginTop: 10,
                  marginBottom: 20,
                  marginRight: 20,
                  color: "blue",
                }}
              >
                Video
              </h1>
              <button
                style={{
                  height: 35,
                  backgroundColor: "green",
                  width: "20%",
                  marginTop: 15,
                  marginRight: 20,
                  borderRadius: 20,
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  fontSize: 16,
                  fontWeight: "500",
                  border: "2px solid green",
                }}
                onClick={openModal}
              >
                Add Video
              </button>
            </div>
            <div className="row order-list">
              {orders.map((video, index) => (
                <div className="col-md-4" key={index}>
                  <div className="cardSlide">
                    <video
                      src={video.VideoUrl}
                      controls
                      width="300"
                      height="200"
                    />
                    <h3>{video.title}</h3>
                    <button
                      className="auth-button3 m-3"
                      onClick={() => handleDelete(video.title)}
                    >
                      Delete This Video
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </main>

        {showModal && (
          <div className="modal-overlay" onClick={closeModal}>
            <div
              className="modal-content"
              onClick={(e) => e.stopPropagation()}
              style={{ backgroundColor: "white", width: "40%" }}
            >
              <h1>Add Video</h1>
              <div className="image-upload">
                {videoFile && (
                  <video
                    src={videoFile}
                    alt="video Preview"
                    style={{ height: 100, width: 100 }}
                  />
                )}
                <br></br>
                <input
                  type="file"
                  accept="video/*"
                  onChange={handleVideoUpload}
                />
                <br></br>
              </div>
              <br></br>
              <input
                type="text"
                placeholder="Enter Your Title"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <div style={{ flexDirection: "row" }}>
                {isLoading ? (
                  <div>Loading...</div>
                ) : (
                  <>
                    <button className="auth-button2 m-3" onClick={handleAdd}>
                      Add Video
                    </button>
                    <button className="auth-button3 m-3" onClick={closeModal}>
                      Close
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
