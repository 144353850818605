import Sidebar from "../Sidebar";
import "../Style.css";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  addDoc,
} from "firebase/firestore"; // Import Firestore functions
import React, { useEffect, useState } from "react";
import { db, app } from "../../../Firebase";
import Header from "../../SubComponent/Header";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default function Catgs() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [title, setTitle] = useState("");
  const [detail, setDetail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const storage = getStorage(app);
      const storageRef = ref(storage, `category/${file.name}`);

      try {
        const snapshot = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        setImageUrl(downloadURL);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const handleAddCategory = async () => {
    if (!imageUrl) {
      alert("Please select an image.");
    } else if (!title || !detail) {
      alert("Please enter title and detail.");
    } else {
      try {
        setIsLoading(true);

        // Create a new category object with the provided data
        const newCategory = {
          imageUrl,
          title,
          detail,
          createdAt: Date.now(),
        };

        // Add the new category to your database or perform any necessary API call
        // For example, you can use Firebase Firestore:
        await addDoc(collection(db, "categories"), newCategory);

        setIsLoading(false);
        closeModal();
        alert("Category added successfully!");

        window.location.reload();

        // Reset the form
        setImageUrl("");
        setTitle("");
        setDetail("");
      } catch (error) {
        console.error("Error adding category:", error);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const getAllCategories = async () => {
    try {
      const categoriesCollection = collection(db, "categories"); // Reference to the 'categories' collection
      const categoriesSnapshot = await getDocs(categoriesCollection);
      const categoriesData = categoriesSnapshot.docs.map((doc) => doc.data());
      setCategories(categoriesData);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching all categories:", error);
    }
  };

  const deleteCategory = async (title) => {
    try {
      const categoriesCollection = collection(db, "categories"); // Reference to the 'categories' collection
      const querySnapshot = await getDocs(categoriesCollection);
      querySnapshot.forEach(async (categoryDoc) => {
        const categoryData = categoryDoc.data();
        if (categoryData.title === title) {
          await deleteDoc(doc(db, "categories", categoryDoc.id)); // Delete the category document
          getAllCategories(); // Fetch updated category list
          console.log("Category deleted:", title);
        }
      });
    } catch (error) {
      console.log("Error deleting category:", error);
    }
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <div className="row" style={{ overflow: "hidden" }}>
        {/* Sidebar (left side) */}
        <div className="col-md-3">
          <Sidebar />
        </div>

        {/* Content (right side) */}
        <main className="col-md-9">
          <Header />
          <br />
          <div className="mt-5 pt-5">
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h1
                style={{
                  fontSize: 30,
                  fontWeight: "bold",
                  fontStyle: "italic",
                  marginTop: 10,
                  marginBottom: 20,
                  marginRight: 20,
                  color: "blue",
                }}
              >
                All Categories
              </h1>
              <button
                style={{
                  height: 35,
                  backgroundColor: "green",
                  width: "20%",
                  marginTop: 15,
                  marginRight: 20,
                  borderRadius: 20,
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  fontSize: 16,
                  fontWeight: "500",
                  border: "2px solid green",
                }}
                onClick={openModal} // Step 4: Open the modal on button click
              >
                Add Category
              </button>
            </div>

            {loading ? (
              <p>Loading...</p>
            ) : (
              <table className="table">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Title</th>
                    <th>Detail</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {categories.map((category) => (
                    <tr key={category.title}>
                      <td>
                        <img
                          src={category.imageUrl}
                          alt={category.title}
                          style={{
                            width: "70px",
                            height: "70px",
                            borderRadius: "5px",
                          }}
                        />
                      </td>
                      <td>{category.title}</td>
                      <td>{category.detail}</td>
                      <td>
                        <button
                          onClick={() => deleteCategory(category.title)}
                          className="btn btn-danger"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </main>
      </div>
      {showModal && (
        <div className="modal-overlay" onClick={closeModal}>
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()}
            style={{ backgroundColor: "white", width: "40%" }}
          >
            {" "}
            {/* Add onClick handler to stop propagation */}
            <h1>Add Category</h1>
            {/* Debugging: Check if imageUrl state is correctly updated */}
            <div className="image-upload">
              {imageUrl && (
                <img
                  src={imageUrl}
                  alt="Category Preview"
                  style={{ height: 100, width: 100 }}
                />
              )}
              <br></br>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
              />
              <br></br>
            </div>
            <br></br>
            <input
              type="text"
              placeholder="Enter Your Title"
              name="title"
              // Add value and onChange handlers to properly handle input changes
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <br></br>
            <input
              type="text"
              placeholder="Enter Your Detail"
              name="detail"
              // Add value and onChange handlers to properly handle input changes
              value={detail}
              onChange={(e) => setDetail(e.target.value)}
            />
            <div style={{ flexDirection: "row" }}>
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                <>
                  <button
                    className="auth-button2 m-3"
                    onClick={handleAddCategory}
                  >
                    Add Category
                  </button>
                  <button className="auth-button3 m-3" onClick={closeModal}>
                    Close
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
