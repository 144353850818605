import React from 'react'
import './file.css'
export default function About() {
  return (
    <div className="about-us">
    <h1>About Us</h1>
 <hr></hr>
    <div className='row'>
<div className='col-lg-4'>
<img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT05VvAmMBuaaSCk1Lq4HEsdLDKd7AhGwg8YlB4Xc40iqWTukS8kAZNC4STxinkvEr0f8E&usqp=CAU" alt="Image 1" />
    
</div>
<div className='col-lg-8' style={{justifyItems:'center'}}>
<h6 style={{wordSpacing:7,lineHeight:2}}>"Plastic bags may be convenient, but the consequences of their convenience are devastating to our planet. Choose reusable, not disposable."</h6>
    <p  style={{wordSpacing:7,lineHeight:2}}>
    Plastic bags have become an integral part of our modern lives. They're convenient, lightweight, and seemingly harmless. However, the convenience comes at a significant environmental cost. Plastic bags are notorious for their adverse effects on the environment and wildlife. 
    </p>
</div>

<div className='col-lg-8' style={{justifyItems:'center'}}>
<h6 style={{wordSpacing:7,lineHeight:2}}>"Let's break up with plastic bags and fall in love with a cleaner, greener planet."</h6>
    <p  style={{wordSpacing:7,lineHeight:2}}>
    Plastic bags have been a ubiquitous part of our shopping routines for decades. However, their convenience hides a darker side: environmental degradation. As we strive for a more sustainable future, breaking up with plastic bags is a meaningful step we can all take. 
    </p>
</div>
<div className='col-lg-4'>
<img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdHGTmTjgyrryqJ5SIY7_fDF1HJ4tZ0SgiuCrJCbn2Tcjg9ZVkrs1pNIO4Nqv27R7WC9U&usqp=CAU" alt="Image 2" />
   
</div>
    </div>
  </div>
  
  )
}
