import { useState, useEffect } from "react";
import Sidebar from "./SideBar";
import "../Style.css";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  addDoc,
} from "firebase/firestore";

import { db } from "../../../Firebase";
import img1 from "../../../img/Welcome.png";
import img2 from "../../../img/ChatBg.png";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Select from "react-select";
import DBRHeader from "../../SubComponent/RSOHeader";
export default function Admin() {
  const [orders, setOrders] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedOrderId, setSelectedOrderId] = useState("");
  const [userData, setUserData] = useState("");

  useEffect(() => {
    // Check if the user is already logged in (stored in AsyncStorage)
    AsyncStorage.getItem("userData").then((userData) => {
      if (userData) {
        // If user data exists, parse it and set the user profile
        const parsedUserData = JSON.parse(userData);
        console.log("User Data:", parsedUserData);
        setUserData(parsedUserData);
      }
    });
  }, []);
  useEffect(() => {
    if (userData) {
      fetchOrders();
    }
  }, [userData]);

  const fetchOrders = async () => {
    try {
      const usersCollection = collection(db, "orders"); // Reference to the 'orders' collection
      const userSnapshot = await getDocs(usersCollection);

      const ordersData = userSnapshot.docs
        .map((doc) => doc.data())
        .filter((order) => order.userDetails.rso === userData.name);

      setOrders(ordersData);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const options = [
    { label: "Select a status to update", value: "" },
    { label: "Pending", value: "Pending" },
    { label: "Approved", value: "Approved" },
    { label: "Rejected", value: "Rejected" },
    { label: "PickedUp", value: "PickedUp" },
    { label: "Delivered", value: "Delivered" },
  ];
  const updateStatus = async () => {
    console.log("Updating Status...");
    if (!selectedOrderId || !selectedStatus) {
      console.warn("Please select an order and a status to update.");
      return;
    }
    console.log("Selected Order ID:", selectedOrderId);
    console.log("Selected Status:", selectedStatus);

    try {
      const q = query(
        collection(db, "orders"),
        where("orderId", "==", selectedOrderId)
      );
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        alert(`No order found with orderId: ${selectedOrderId}`);
        return;
      }

      const orderDoc = querySnapshot.docs[0];
      const orderRef = doc(db, "orders", orderDoc.id);

      await updateDoc(orderRef, { orderStatus: selectedStatus });

      const selectedOrder = orders.find(
        (order) => order.orderId === selectedOrderId
      );
      console.log("selectedOrder", selectedOrder);
      if (selectedOrder) {
        // Extract relevant information
        const toid = selectedOrder.userEmail;
        const rso = selectedOrder.userDetails.rso; // Update with the actual field name
        const dbr = selectedOrder.userDetails.dbr; // Update with the actual field name

        // Create newNotificationData
        const newNotificationData = {
          fromid: userData.email, // Sender's email
          toid: toid, // Targeted order ID
          rso: rso,
          dbr: dbr,
          subject: `Order Status Updated for ${selectedOrderId}`,
          message: `Your Order Status Has Been Updated to ${selectedStatus}`,
          timestamp: new Date(), // You can include a message if needed
        };
        const addNotification = async (newNotificationData) => {
          try {
            const notificationCollection = collection(db, "notifications");

            // Use addDoc to add a new document to the collection
            const newDocRef = await addDoc(
              notificationCollection,
              newNotificationData
            );

            console.log("Notification added with ID: ", newDocRef.id);
          } catch (error) {
            console.error("Error adding notification: ", error);
          }
        };
        await addNotification(newNotificationData);
        console.log("Order status updated successfully.");
        alert("Order status updated successfully.");
        fetchOrders(); // Refresh the orders list after updating status
      } else {
        console.warn(`No order found with orderId: ${selectedOrderId}`);
      }
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  const MySelect = ({ selectedStatus, setSelectedStatus }) => {
    return (
      <Select
        options={options}
        value={options.find((option) => option.value === selectedStatus)}
        onChange={(selectedOption) => setSelectedStatus(selectedOption.value)}
      />
    );
  };
  const OrderItem = ({ item }) => {
    return (
      <div className="order-container">
        <div className="header-container">
          <img src={img1} alt="Bag Icon" className="bag-icon" />
          <p className="header-text">Order ID: {item.orderId}</p>
        </div>
        <div className="details-container">
          <div className="user-details-container">
            <img src={img2} alt="User Icon" className="user-icon" />
            <p className="user-text">Name: {item.userDetails.name}</p>
            <p className="user-text">Email: {item.userDetails.email}</p>
            <p className="user-text">Phone: {item.userDetails.number}</p>
            <p className="user-text">
              Alternate: {item.userDetails.alternateNumber}
            </p>
            <p className="user-text">City: {item.userDetails.city}</p>
            <p className="user-text">Address: {item.userDetails.address}</p>

            <p className="user-text2">Status: {item.orderStatus}</p>
            <p className="user-text2">
              Distributer: {item.userDetails.distributer}
            </p>
            <p className="user-text2">RSO: {item.userDetails.rso}</p>
          </div>
          <div className="bag-details-container">
            {item.items.map((bagItem, index) => (
              <div key={index} className="bag-item-container">
                <p className="meal-text3">{bagItem.title}</p>
                <p className="meal-text">{bagItem.category}</p>
                <p className="meal-text">Quantity: {bagItem.quantity}KG</p>
                <p className="meal-text2">Price: ₹{bagItem.price}</p>
              </div>
            ))}
            <p className="total-text">Total Amount: ₹{item.totalAmount}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="admin-page">
      <div className="row">
        {/* Sidebar (left side) */}
        <div className="col-md-3">
          <Sidebar />
        </div>

        {/* Content (right side) */}
        <main className="col-md-9" style={{ background: "aliceblue" }}>
          <DBRHeader />

          <div className="row pt-5 mt-5 pb-4">
            <div className="col-lg-5 mt-4">
              <select
                value={selectedOrderId}
                onChange={(e) => setSelectedOrderId(e.target.value)}
                style={{ width: "100%", height: "40px" }}
              >
                <option value="">Select OrderId</option>
                {orders.map((orders) => (
                  <option key={orders.id} value={orders.title}>
                    {orders.orderId}
                  </option>
                ))}
              </select>
            </div>
            <br></br>
            <div className="col-lg-4 mt-4">
              <MySelect
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
                styles={{ control: (base) => ({ ...base, width: "50%" }) }} // Add the styles prop
              />
              <br></br>
            </div>
            <div className="col-lg-2 mt-2">
              <button
                style={{
                  height: 35,
                  backgroundColor: "green",
                  width: "100%",
                  marginTop: 15,
                  marginRight: 20,
                  borderRadius: 20,
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  fontSize: 16,
                  fontWeight: "500",
                  border: "2px solid green",
                  marginLeft: 12,
                }}
                onClick={updateStatus}
              >
                Update Status
              </button>
            </div>
          </div>

          <div className="">
            <h1 className="admin-page-heading">All Orders</h1>
            <div className="order-list">
              {orders.map((order, index) => (
                <div className="col-md-6" style={{ background: "white" }}>
                  <OrderItem key={index} item={order} />
                </div>
              ))}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
