import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../Firebase.js";
import AsyncStorage from "@react-native-async-storage/async-storage";

const AllOrders = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const UserData = await AsyncStorage.getItem("userData");
      const parseData = JSON.parse(UserData);
      const emailId = parseData.email;

      const itemsCollection = collection(db, "orders");
      const itemsSnapshot = await getDocs(itemsCollection);

      // Filter orders by user email
      const ordersData = itemsSnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((order) => order.userEmail === emailId);
      console.log(ordersData);
      setOrders(ordersData);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };
  const getStatusColor = (status) => {
    switch (status) {
      case "Pending":
        return "red";
      case "Approved":
        return "blue";
      case "Complete":
        return "green";
      default:
        return "black"; // Default color if status doesn't match any of the above
    }
  };
  const renderOrder = (order) => (
    <div key={order.id} style={styles.orderContainer}>
      <div style={styles.orderHeader}>
        <h3 style={{ color: "blue" }}>Order ID: {order.orderId}</h3>
        <p style={{ color: getStatusColor(order.orderStatus) }}>
          <b>Status: </b>
          {order.orderStatus}
        </p>
      </div>
      <div style={styles.orderDetails}>
        <div style={styles.customerInfo}>
          <h4 style={{ color: "orange" }}>Retailer Details</h4>
          <p>Name: {order.userDetails.name}</p>
          <p>Email: {order.userDetails.email}</p>
          <p>Phone: {order.userDetails.number}</p>
          <p>Address: {order.userDetails.address}</p>
          <p>Distributer: {order.userDetails.distributer}</p>
          <p>RSO: {order.userDetails.rso}</p>
        </div>
        <div style={styles.itemsList}>
          <h4 style={{ color: "orange" }}>Ordered Items</h4>
          <ul>
            {order.items.map((item, index) => (
              <li key={index}>
                <strong>{item.title}</strong>
                <p>Category: {item.category}</p>
                <p>Quantity: {item.quantity}KG</p>
                <p>Price: ₹{item.price}</p>
                <p>Color: {item.selectedColor}</p>
                <p>Size: {item.selectedSize}</p>
                <p>Remark: {item.remark}</p>
              </li>
            ))}
          </ul>
          <hr></hr>
          <p style={{ color: "green", fontWeight: "bold" }}>
            Total Amount: ₹{order.totalAmount}
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <h1 style={styles.headingText}>My Orders</h1>
      <div style={styles.container}>
        {orders.length === 0 ? (
          <p style={styles.emptyText}>No orders found.</p>
        ) : (
          orders.map((order) => renderOrder(order))
        )}
      </div>
    </>
  );
};

const styles = {
  container: {
    padding: "20px",
    backgroundColor: "#F5F5F5",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  headingText: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "10px",
    color: "black",
    padding: 20,
  },
  orderContainer: {
    backgroundColor: "white",
    borderRadius: "10px",
    marginBottom: "15px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
    padding: "20px",
    width: "50%",
  },
  orderHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  orderDetails: {
    display: "flex",
    justifyContent: "space-between",
  },
  customerInfo: {
    flex: "1",
  },
  itemsList: {
    flex: "1",
  },
  emptyText: {
    fontSize: "16px",
    alignSelf: "center",
    color: "black",
  },
};

export default AllOrders;
