import Sidebar from "../Sidebar";
import "../Style.css";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  addDoc,
} from "firebase/firestore"; // Import Firestore functions
import React, { useEffect, useState } from "react";
import { db, app } from "../../../Firebase";
import Header from "../../SubComponent/Header";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default function Catgs() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [title, setTitle] = useState("");
  const [detail, setDetail] = useState("");
  const [color, setColor] = useState("");
  const [s, setS] = useState("");
  const [c, setC] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const storage = getStorage(app);
      const storageRef = ref(storage, `category/${file.name}`);

      try {
        const snapshot = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        setImageUrl(downloadURL);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const handleAddColor = async () => {
    if (!color) {
      alert("Please enter color.");
    } else {
      try {
        setIsLoading(true);

        // Create a new category object with the provided data
        const newCategory = {
         color,
          createdAt: Date.now(),
        };

        // Add the new category to your database or perform any necessary API call
        // For example, you can use Firebase Firestore:
        await addDoc(collection(db, "color"), newCategory);

        setIsLoading(false);
        closeModal1();
        alert("Color added successfully!");

        window.location.reload();

        
        setColor("")
      } catch (error) {
        console.error("Error adding category:", error);
        setIsLoading(false);
      }
    }
  };
  const handleAddSize = async () => {
    if (!detail) {
      alert("Please enter Size.");
    } else {
      try {
        setIsLoading(true);

        // Create a new category object with the provided data
        const newCategory = {
          detail,
          createdAt: Date.now(),
        };

        // Add the new category to your database or perform any necessary API call
        // For example, you can use Firebase Firestore:
        await addDoc(collection(db, "size"), newCategory);

        setIsLoading(false);
        closeModal2();
        alert("Size added successfully!");

        window.location.reload();

     
        setDetail("");
      } catch (error) {
        console.error("Error adding category:", error);
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    getAllSize();
    getAllColor();
  }, []);

  const openModal1 = () => {
    setShowModal1(true);
  };

  const closeModal1 = () => {
    setShowModal1(false);
  };
  const openModal2 = () => {
    setShowModal2(true);
  };

  const closeModal2 = () => {
    setShowModal2(false);
  };
  const getAllSize = async () => {
    try {
      const categoriesCollection = collection(db, "size"); // Reference to the 'categories' collection
      const categoriesSnapshot = await getDocs(categoriesCollection);
      const categoriesData = categoriesSnapshot.docs.map((doc) => doc.data());
      setS(categoriesData);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching all categories:", error);
    }
  };
  const getAllColor = async () => {
    try {
      const categoriesCollection = collection(db, "color");
      const categoriesSnapshot = await getDocs(categoriesCollection);
      const categoriesData = categoriesSnapshot.docs.map((doc) => doc.data());
      console.log("categoriesData:", categoriesData);
      setC(categoriesData);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching all categories:", error);
    }
  };

  const deleteColor = async (color) => {
    try {
      const categoriesCollection = collection(db, "color"); // Reference to the 'categories' collection
      const querySnapshot = await getDocs(categoriesCollection);
      querySnapshot.forEach(async (categoryDoc) => {
        const categoryData = categoryDoc.data();
        if (categoryData.color === color) {
          await deleteDoc(doc(db, "color", categoryDoc.id)); // Delete the category document
          getAllColor(); 
          console.log("Color deleted:", color);
        }
      });
    } catch (error) {
      console.log("Error deleting category:", error);
    }
  };
  const deleteSize = async (detail) => {
    try {
      const categoriesCollection = collection(db, "size"); // Reference to the 'categories' collection
      const querySnapshot = await getDocs(categoriesCollection);
      querySnapshot.forEach(async (categoryDoc) => {
        const categoryData = categoryDoc.data();
        if (categoryData.detail === detail) {
          await deleteDoc(doc(db, "size", categoryDoc.id)); // Delete the category document
          getAllSize(); 
          console.log("Category deleted:", detail);
        }
      });
    } catch (error) {
      console.log("Error deleting category:", error);
    }
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <div className="row" style={{ overflow: "hidden" }}>
        {/* Sidebar (left side) */}
        <div className="col-md-3">
          <Sidebar />
        </div>

        {/* Content (right side) */}
        <main className="col-md-9">
          <Header />
          <br />
          <div className="mt-5 pt-5">
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
             
        <>
        <h1
                style={{
                  fontSize: 30,
                  fontWeight: "bold",
                  fontStyle: "italic",
                  marginTop: 10,
                  marginBottom: 20,
                  marginRight: 20,
                  color: "blue",
                }}
              >
                All Color
              </h1>
              <button
                style={{
                  height: 35,
                  backgroundColor: "green",
                  width: "20%",
                  marginTop: 15,
                  marginRight: 20,
                  borderRadius: 20,
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  fontSize: 16,
                  fontWeight: "500",
                  border: "2px solid green",
                }}
                onClick={openModal1} // Step 4: Open the modal on button click
              >
                Add Color
              </button>
             
              </>
              <>
              <h1
                style={{
                  fontSize: 30,
                  fontWeight: "bold",
                  fontStyle: "italic",
                  marginTop: 10,
                  marginBottom: 20,
                  marginRight: 20,
                  color: "blue",
                }}
              >
                All Size
              </h1>
              <button
                style={{
                  height: 35,
                  backgroundColor: "green",
                  width: "20%",
                  marginTop: 15,
                  marginRight: 20,
                  borderRadius: 20,
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  fontSize: 16,
                  fontWeight: "500",
                  border: "2px solid green",
                }}
                onClick={openModal2} // Step 4: Open the modal on button click
              >
                Add Size
              </button>
             
              </>
           </div>
           <hr></hr>
           <div style={{display:'flex', justifyContent:'space-between'}}>
            <div style={{width:500}}>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <table className="table">
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Color</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                {Array.isArray(c) && c.map((item, index) => (
  <tr key={item.color}>
    <td>{index + 1}</td>
    <td>{item.color}</td>
    <td>
      <button
        onClick={() => deleteColor(item.color)}
        className="btn btn-danger"
      >
        Delete
      </button>
    </td>
  </tr>
))}

                </tbody>
              </table>
            )}
            </div>
            <div style={{width:500}}>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <table className="table">
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Size</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                {s.map((s, index) => (
    <tr key={s.detail}>
      <td>{index + 1}</td>
                 
                      <td>{s.detail}</td>
                      <td>
                        <button
                          onClick={() => deleteSize(s.detail)}
                          className="btn btn-danger"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            </div>
           </div>
          </div>
        </main>
      </div>
      {showModal1 && (
        <div className="modal-overlay" onClick={closeModal1}>
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()}
            style={{ backgroundColor: "white", width: "40%" }}
          >
            {" "}
            {/* Add onClick handler to stop propagation */}
            <h1>Add Color</h1>
       
            <input
              type="text"
              placeholder="Enter New Color"
              name="color"
              // Add value and onChange handlers to properly handle input changes
              value={color}
              onChange={(e) => setColor(e.target.value)}
            />
            <div style={{ flexDirection: "row" }}>
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                <>
                  <button
                    className="auth-button2 m-3"
                    onClick={handleAddColor}
                  >
                    Add Color
                  </button>
                  <button className="auth-button3 m-3" onClick={closeModal1}>
                    Close
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}

{showModal2 && (
        <div className="modal-overlay" onClick={closeModal2}>
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()}
            style={{ backgroundColor: "white", width: "40%" }}
          >
            {" "}
            {/* Add onClick handler to stop propagation */}
            <h1>Add Size</h1>
            {/* Debugging: Check if imageUrl state is correctly updated */}
            
            
            <br></br>
            <input
              type="text"
              placeholder="Enter Product Size"
              name="detail"
              // Add value and onChange handlers to properly handle input changes
              value={detail}
              onChange={(e) => setDetail(e.target.value)}
            />
            <div style={{ flexDirection: "row" }}>
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                <>
                  <button
                    className="auth-button2 m-3"
                    onClick={handleAddSize}
                  >
                    Add Size
                  </button>
                  <button className="auth-button3 m-3" onClick={closeModal2}>
                    Close
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
