import React, { useState } from "react";
import "./contact.css"; // Import the CSS file
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../Firebase";

function ContactUs() {
  const initialFormData = {
    name: "",
    email: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log("Form data submitted:", formData);
      await addDoc(collection(db, "contact"), formData);
      setIsSubmitted(true);

      // Reset the form data to its initial empty state
      setFormData(initialFormData);

      alert("Contact Email Sent Successfully!");
    } catch (error) {
      console.error("Error adding category:", error);
    }
  };

  return (
    <div className="contact-container">
      {isSubmitted && <p>Thank you for your message!</p>}
      <form onSubmit={handleSubmit}>
        <h1>Contact Us</h1>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows="4"
            required
          />
        </div>
        <div style={{ textAlign: "center" }}>
          <button
            type="submit"
            style={{
              backgroundColor: "blue",
              borderRadius: 20,
              width: "fit-content",
            }}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default ContactUs;
