import React, { useState, useEffect } from "react";
import { db, app } from "../../../Firebase";
import Sidebar from "../Sidebar";
import Header from "../../SubComponent/Header";
import "../Admin.css";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  addDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

export default function Admin() {
  const storage = getStorage(app);

  const [sliders, setSliders] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [title, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchSliders();
  }, []);

  const fetchSliders = async () => {
    try {
      const slidersCollection = collection(db, "sliders");
      const slidersSnapshot = await getDocs(slidersCollection);
      const slidersData = slidersSnapshot.docs.map((doc) => doc.data());
      setSliders(slidersData);
    } catch (error) {
      console.error("Error fetching sliders:", error);
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileSize = file.size;
      const maxSize = 20 * 1024 * 1024; // 20MB maximum size for image

      if (fileSize > maxSize) {
        alert("File size exceeds the limit (20MB max).");
        event.target.value = null;
      } else {
        setImageFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImageUrl(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleDelete = async (title) => {
    try {
      const slidersCollection = collection(db, "sliders");
      const querySnapshot = await getDocs(slidersCollection);
      querySnapshot.forEach(async (slideDoc) => {
        const slideData = slideDoc.data();
        if (slideData.title === title) {
          await deleteDoc(doc(db, "sliders", slideDoc.id));
          fetchSliders();
          alert("Slide deleted successfully");
        }
      });
    } catch (error) {
      console.log("Error deleting slide:", error);
    }
  };

  const handleAdd = async () => {
    if (!imageFile) {
      alert("Please select an image.");
    } else if (!title) {
      alert("Please enter title and details.");
    } else {
      try {
        setIsLoading(true);

        const storageRef = ref(storage, `sliders/${imageFile.name}`);
        const uploadTask = uploadBytesResumable(storageRef, imageFile);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Handle progress if needed
          },
          async (error) => {
            setIsLoading(false);
            console.error("Error uploading:", error);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

            const newSlide = {
              imageUrl: downloadURL,
              title,
              createdAt: Date.now(),
            };

            await addDoc(collection(db, "sliders"), newSlide);

            setIsLoading(false);
            closeModal();
            alert("Slide added successfully!");
            window.location.reload();

            setImageFile(null);
            setImageUrl("");
            setTitle("");
          }
        );
      } catch (error) {
        setIsLoading(false);
        console.error("Error adding Slide:", error);
      }
    }
  };

  return (
    <div className="admin-page">
      <div className="row">
        <div className="col-md-3">
          <Sidebar />
        </div>
        <main className="col-md-9">
          <Header />
          <div className="mt-5 pt-5">
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h1
                style={{
                  fontSize: 30,
                  fontWeight: "bold",
                  fontStyle: "italic",
                  marginTop: 10,
                  marginBottom: 20,
                  marginRight: 20,
                  color: "blue",
                }}
              >
                Sliders
              </h1>
              <button
                style={{
                  height: 35,
                  backgroundColor: "green",
                  width: "20%",
                  marginTop: 15,
                  marginRight: 20,
                  borderRadius: 20,
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  fontSize: 16,
                  fontWeight: "500",
                  border: "2px solid green",
                }}
                onClick={openModal}
              >
                Add Slide
              </button>
            </div>
            <div className="row order-list">
              {sliders.map((slide, index) => (
                <div className="col-md-4" key={index}>
                  <div className="cardSlide">
                    <img
                      src={slide.imageUrl}
                      alt={`Slide ${index}`}
                      style={{ width: 300, height: 200 }}
                    />
                    <h3>{slide.title}</h3>
                    <button
                      className="auth-button3 m-3"
                      onClick={() => handleDelete(slide.title)}
                    >
                      Delete This Slide
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </main>

        {showModal && (
          <div className="modal-overlay" onClick={closeModal}>
            <div
              className="modal-content"
              onClick={(e) => e.stopPropagation()}
              style={{ backgroundColor: "white", width: "40%" }}
            >
              <h1>Add Slide</h1>
              <div className="image-upload">
                {imageFile && (
                  <img
                    src={imageUrl}
                    alt="Slide Preview"
                    style={{ height: 100, width: 100 }}
                  />
                )}
                <br />
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                />
                <br />
              </div>
              <br />
              <input
                type="text"
                placeholder="Enter Slide Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <div style={{ flexDirection: "row" }}>
                {isLoading ? (
                  <div>Loading...</div>
                ) : (
                  <>
                    <button className="auth-button2 m-3" onClick={handleAdd}>
                      Add Slide
                    </button>
                    <button className="auth-button3 m-3" onClick={closeModal}>
                      Close
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
