import React from "react";
import "./Footer.css"; // Import your CSS file for styling
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h2>About Us</h2>
          <p>BIOPIN | AGROPLA is your ultimate online shopping destination.</p>
        </div>
        <div className="footer-section">
          <h2>Contact Us</h2>
          <address>
            Email: contact@example.com
            <br />
            Phone: +1 123-456-7890
          </address>
        </div>
        <div className="footer-section">
          <h2>Follow Us</h2>

          <div className="social-icons">
            <a href="#" className="icon">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a href="#" className="icon">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a href="#" className="icon">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2023 Your Website. All rights reserved.</p>
      </div>
    </footer>
  );
}
