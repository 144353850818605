import Sidebar from '../Sidebar';
import '../Style.css';
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  addDoc
} from 'firebase/firestore'; // Import Firestore functions
import React, { useEffect, useState } from 'react';
import { db } from '../../../Firebase';
import Header from '../../SubComponent/Header';
export default function Catgs() {
  const [contact, setContact] = useState([]);
  const [loading, setLoading] = useState(true);




  useEffect(() => {
    getAllCategories();
  }, []);


  const getAllCategories = async () => {
    try {
      const categoriesCollection = collection(db, 'contact'); // Reference to the 'contact' collection
      const categoriesSnapshot = await getDocs(categoriesCollection);
      const categoriesData = categoriesSnapshot.docs.map((doc) => doc.data());
      setContact(categoriesData);
      console.log(categoriesData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching all categories:', error);
      setLoading(false); // Make sure to set loading to false even in case of an error
    }
  };
  
  const deleteCategory = async (message) => {
    try {
      const categoriesCollection = collection(db, 'contact'); // Reference to the 'categories' collection
      const querySnapshot = await getDocs(categoriesCollection);
      querySnapshot.forEach(async (categoryDoc) => {
        const categoryData = categoryDoc.data();
        if (categoryData.message === message) {
          await deleteDoc(doc(db, 'contact', categoryDoc.id)); // Delete the category document
          getAllCategories(); // Fetch updated category list
          console.log('Category deleted:', message);
          alert('contact delete sucessfully.');
        }
      });
    } catch (error) {
      console.log('Error deleting category:', error);
    }
  };

  return (
    <div style={{ overflow: 'hidden' }}>
      <div className="row" style={{ overflow: 'hidden' }}>
        {/* Sidebar (left side) */}
        <div className="col-md-3">
          <Sidebar />
        </div>

        {/* Content (right side) */}
        <main className="col-md-9">
          <Header />
          <br />
          <div className='mt-5 pt-5'>

            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
              <h1
                style={{
                  fontSize: 30,
                  fontWeight: 'bold',
                  fontStyle: 'italic',
                  marginTop: 10,
                  marginBottom: 20,
                  marginRight: 20,
                  color: 'blue',
                }}
              >
                Contact Form Details
              </h1>
              
            </div>

            {loading ? (
              <p>Loading...</p>
            ) : (
              <table className="table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Message</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {contact.map((category) => (
                    <tr key={category.title}>
                       
                      <td>{category.name}</td>
                      <td>{category.email}</td>
                      <td>{category.message}</td>
                      <td>
                        <button
                          onClick={() => deleteCategory(category.message)}
                          className="btn btn-danger"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </main>
      </div>
    



    </div>
  );
}
