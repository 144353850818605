// firebase.js

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAbsRA7GvhJs_fl1bWt8dsBxfgBgtlfiA4",
  authDomain: "shoppinghubtechies.firebaseapp.com",
  projectId: "shoppinghubtechies",
  storageBucket: "shoppinghubtechies.appspot.com",
  messagingSenderId: "168140559106",
  appId: "1:168140559106:web:394c5b97c6898736e1681b",
  measurementId: "G-53F0LDDM82",
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db, app };
