import React, { useEffect } from "react";
import Sidebar from "./Sidebar";
import "./Style.css";
import { useNavigate } from "react-router-dom";
import logoImage from "../../img/LOGO.jpeg";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default function Admin() {
  const history = useNavigate();

  useEffect(() => {
    const checkUser = async () => {
      try {
        // Check if the user is already logged in (stored in AsyncStorage)
        const userData = await AsyncStorage.getItem("userData");
        console.log("userData:", userData); // Log the userData retrieved from AsyncStorage

        if (!userData) {
          // If userData is null, navigate to "/"
          console.log("User is not logged in. Redirecting...");
          history("/");
          return; // Exit the function
        }

        // If user data exists, parse it
        const parsedUserData = JSON.parse(userData);
        console.log("parsedUserData:", parsedUserData); // Log the parsedUserData

        if (parsedUserData.type !== "Admin") {
          // If user type is not "Admin", navigate to "/"
          console.log("User is not an Admin. Redirecting...");
          history("/");
        }
      } catch (error) {
        console.error("Error checking user:", error);
      }
    };

    checkUser();
  }, []);

  return (
    <div style={{ overflow: "hidden" }}>
      <div className="row" style={{ overflow: "hidden" }}>
        {/* Sidebar (left side) */}
        <div className="col-md-3">
          <Sidebar />
        </div>

        {/* Content (right side) */}
        <main className="col-md-9">
          <img
            src={logoImage}
            alt="EcomArea Logo"
            style={{ height: 90, width: 200 }}
          />
          <br />
          <h2 className="my-4"> BIOPIN | AGROPLA / Dashboard</h2>
          <div className="row">
            {/* Card 1 */}
            <div className="col-lg-5 col-md-6 col-sm-12 mb-4">
              <div
                className="card shadow"
                style={{ width: "100%", height: "180px" }}
              >
                <div className="card-body">
                  <h5 className="card-title">All Products</h5>
                  <p className="card-text">
                    You have to see All Product Click on this button.
                  </p>
                  <button
                    className="btn btn-primary"
                    onClick={() => history("/addproduct")}
                  >
                    View More
                  </button>
                </div>
              </div>
            </div>

            {/* Card 2 */}
            <div className="col-lg-5 col-md-6 col-sm-12 mb-4">
              <div
                className="card shadow"
                style={{ width: "100%", height: "180px" }}
              >
                <div className="card-body">
                  <h5 className="card-title">All Orders</h5>
                  <p className="card-text">
                    You have to see All Orders Click on this button.
                  </p>
                  <button
                    className="btn btn-primary"
                    onClick={() => history("/orders")}
                  >
                    View More
                  </button>
                </div>
              </div>
            </div>

            {/* Card 3 */}
            <div className="col-lg-5 col-md-6 col-sm-12 mb-4 ">
              <div
                className="card shadow"
                style={{ width: "100%", height: "180px" }}
              >
                <div className="card-body">
                  <h5 className="card-title">All Users</h5>
                  <p className="card-text">
                    You have to see All users Click on this button.
                  </p>
                  <button
                    className="btn btn-primary"
                    onClick={() => history("/users")}
                  >
                    View More
                  </button>
                </div>
              </div>
            </div>

            {/* Card 4 */}
            <div className="col-lg-5 col-md-6 col-sm-12 mb-4 ">
              <div
                className="card shadow"
                style={{ width: "100%", height: "180px" }}
              >
                <div className="card-body">
                  <h5 className="card-title">Contact Details</h5>
                  <p className="card-text">
                    You have to see All users Contact Details Click on this
                    button.
                  </p>
                  <button
                    className="btn btn-primary"
                    onClick={() => history("/Allcontact")}
                  >
                    View More
                  </button>
                </div>
              </div>
            </div>

            {/* Card 5 */}

            <div className="col-lg-5 col-md-6 col-sm-12 mb-4 ">
              <div
                className="card shadow"
                style={{ width: "100%", height: "180px" }}
              >
                <div className="card-body">
                  <h5 className="card-title">Slide Details</h5>
                  <p className="card-text">
                    You have to see Slide details Click on this button.
                  </p>
                  <button
                    className="btn btn-primary"
                    onClick={() => history("/slider")}
                  >
                    View More
                  </button>
                </div>
              </div>
            </div>

            {/* Card 6 */}
            <div className="col-lg-5 col-md-6 col-sm-12 mb-4 ">
              <div
                className="card shadow"
                style={{ width: "100%", height: "180px" }}
              >
                <div className="card-body">
                  <h5 className="card-title">Video Details</h5>
                  <p className="card-text">
                    You have to see All promotion videos Click on this button.
                  </p>
                  <button
                    className="btn btn-primary"
                    onClick={() => history("/video")}
                  >
                    View More
                  </button>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
