import React, { useEffect, useState } from "react";
import { getDocs, collection, doc, updateDoc } from "firebase/firestore";
import { db } from "../../../Firebase";
import Sidebar from "../Sidebar";
import Header from "../../SubComponent/Header";
import "../Style.css";

export default function Product() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllOrders();
  }, []);

  const getAllOrders = async () => {
    try {
      const ordersCollection = collection(db, "dirOrder");
      const ordersSnapshot = await getDocs(ordersCollection);
      const ordersData = ordersSnapshot.docs.map((doc) => {
        const orderData = doc.data();
        const timestamp = orderData.timestamp.toDate(); // Convert timestamp to a Date object
        return { ...orderData, id: doc.id, timestamp };
      });

      // Sort orders based on timestamp in descending order
      ordersData.sort((a, b) => b.timestamp - a.timestamp);

      setOrders(ordersData);
      setLoading(false);
    } catch (error) {
      console.log("Error fetching orders:", error);
    }
  };

  const handleStatusUpdate = async (orderId, newStatus) => {
    try {
      const orderRef = doc(db, "dirOrder", orderId);
      await updateDoc(orderRef, { status: newStatus });
      getAllOrders();
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <div className="row" style={{ overflow: "hidden" }}>
        <div className="col-md-3">
          <Sidebar />
        </div>
        <main className="col-md-8">
          <Header />
          <br />
          <div className="mt-5 pt-5">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h1
                style={{
                  fontSize: 30,
                  fontWeight: "bold",
                  fontStyle: "italic",
                  marginTop: 10,
                  marginBottom: 20,
                  marginRight: 20,
                  color: "blue",
                }}
              >
                Distributer's Orders
              </h1>
            </div>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <table className="table">
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>Grand Total</th>
                    <th>User Email</th>
                    <th>Date and Time</th>
                    <th>Status</th>
                    <th>Products</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <tr key={order.id}>
                      <td>{order.id}</td>
                      <td>₹{order.grandTotal}</td>
                      <td>{order.userEmail}</td>
                      <td>{order.timestamp.toLocaleString()}</td>{" "}
                      {/* Use toLocaleString directly */}
                      <td>
                        <span
                          style={{
                            padding: "8px 12px",
                            borderRadius: "5px",
                            fontWeight: 600,
                            color:
                              order.status === "Pending"
                                ? "orange"
                                : order.status === "Approved"
                                ? "red"
                                : "green",
                          }}
                        >
                          {order.status}
                        </span>
                      </td>
                      <td>
                        <ul>
                          {order.products.map((product, index) => (
                            <li key={index}>
                              {product.title} - Quantity: {product.quantity} -
                              Total Price: ₹{product.totalPrice}
                            </li>
                          ))}
                        </ul>
                      </td>
                      <td>
                        {order.status === "Pending" && (
                          <button
                            onClick={() =>
                              handleStatusUpdate(order.id, "Approved")
                            }
                            style={{ backgroundColor: "red", color: "#fff" }}
                          >
                            Mark as Approved
                          </button>
                        )}
                        {order.status === "Approved" && (
                          <button
                            onClick={() =>
                              handleStatusUpdate(order.id, "Complete")
                            }
                            style={{ backgroundColor: "green", color: "#fff" }}
                          >
                            Mark as Complete
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}
